import { addDoc, collection, getDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { db } from "./components/account/account";



$('body').append('<div class="modal" tabindex="-1" id="sendemails" data-bs-keyboard="false" data-bs-backdrop="static"></div>');{
    $('#sendemails').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="sendemailstop"></div>');
    $('#sendemailstop').append('<div class="modal-content dialogs-content" id="sendemailscontent"></div>')

    $('#sendemailscontent').append('<div class="modal-header centered-header dialogs-header" id="sendemailsheader"></div>')
    $('#sendemailscontent').append('<div class="modal-body dialogs-body" id="sendemailsbody"></div>')

    $('#sendemailsheader').append('<div class="modal-title dialogs-title" id="sendemailstitle"><span class="mdi mdi-email-sync-outline dialogs-icon"></span>Sending Emails</div>')
    $('#sendemailsheader').append('<div class="dialogs-closeicon" id="sendemailsclosebutton"><span class="mdi mdi-window-close"></span></div>');


    $('#sendemailsclosebutton').on('click', function(event) {
        $('#sendemails').modal('hide');
    });

}

var emails = [];
var subject;
var html;
var text;
var total;
var saveok;
var saveerror;
var sendok;
var senderror;
var url;
var pending = []
var requested = []
const mailsRef = collection(db,'mail')

function updateBody(){
    $('#sendemailsbody').html('Total : ' + total + '<br>DB-OK : ' + saveok + '<br>DB-Error : ' + saveerror + '<br>MAIL-OK : ' + sendok + '<br>Mail-Error : ' + senderror + '<br>Mail-Pending : ' + requested.length)
}

function filteremail(){
    for (let i = 0; i < emails.length; i++){

        const email = emails[i];
        const mailsQuery = query(mailsRef,where('to','==',email)) 

        getDocs(mailsQuery)
        .then(snapshot => {
            let gotNL = false;
            snapshot.forEach(usermails => {
                const data = usermails.data();
                if ((data.url == url) && (data.delivery.state != 'SUCCESS')) gotNL = true;
            })
            
            if (!gotNL) {
                pending.push(email)
                total++;
                updateBody();
            }
        })
    }
}

function sendemail(){
    if (pending.length != 0){
        const email = pending.pop();
        console.log('sending email to: ' + email)

        addDoc(mailsRef,{
            to: email,
            message:{
                subject: subject,
                html: html,
                text: text,
            },
            created: serverTimestamp(),
            newsletter: url,
        })
        .then(docRef => {
            requested.push(docRef.id)
            saveok++;
            updateBody();
        })
        .catch(error => {
            console.error(error)
            saveerror++;
            updateBody();
        })
    }
    if (requested.length){
        const id = requested[0];
        getDoc(doc(db,'mail',id)) 
        .then(snapshot => {
            if (snapshot.exists()){
                data = snapshot.data();
                if (data.delivery && data.delivery.state){
                    if (data.delivery.state == 'SUCCESS'){
                        sendok++;
                        requested.shift();
                        updateBody();
                    }else if (data.delivery.state == 'ERROR'){
                        senderror++;
                        requested.shift();
                        updateBody();
                    }
                }
            }
        })
        .catch(error => {
            console.error(error);
        })
    }
}


export function sendemails(e,s,h,t,u){
    emails = structuredClone(e)
    pending = [];
    requested = [];
    subject = s;
    html= h;
    text = t;
    url = u;
    total = 0;
    saveok = 0;
    saveerror = 0;
    sendok = 0;
    senderror = 0;
    filteremail();
    setInterval(sendemail,30000)
    $('#sendemails').modal('show');    
}

