import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./components/account/account";
import { Message } from "./components/Message/message";

var theNumber;
var checknumber = null;
var interval = null;

const TESTNUMBER = 'henrik@raketten.net'

$('body').append('<div class="modal" id="MAIL" data-bs-keyboard="false"></div>');{
    $('#MAIL').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="MAILtop"></div>');
    $('#MAILtop').append('<div class="modal-content dialogs-content" id="MAILcontent"></div>')

    $('#MAILcontent').append('<div class="modal-header centered-header dialogs-header" id="MAILheader"></div>')
    $('#MAILcontent').append('<div id="MAILbody" class="modal-body dialogs-body"></div>')
    $('#MAILcontent').append('<div class="modal-footer dialogs-footer" id="MAILfooter"></div>')

    $('#MAILheader').append('<svg class="svg-icon27 lightsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"/></svg>')
    $('#MAILheader').append('<div class="modal-title">Send MAIL</div>')
    $('#MAILheader').append('<div id="MAILclosebutton"><span class="mdi mdi-window-close"></span></div>');

    $('#MAILbody').append('<label for="MAIL-subject" class="dialogs-label">MAIL Subject</label>');
    $('#MAILbody').append('<input type="text" style="width:100%;" id="MAIL-subject" placeholder="Input subject here...">')
    $('#MAIL-subject').val('Message from HR Bowling')

    $('#MAILbody').append('<label for="MAIL-html" class="dialogs-label">MAIL HTML</label>');
    $('#MAILbody').append('<textarea style="width:100%;" rows="3" id="MAIL-html" placeholder="Input HTML message here..."></textarea>')


    $('#MAILbody').append('<label for="MAIL-text" class="dialogs-label">MAIL Text</label>');
    $('#MAILbody').append('<textarea style="width:100%;" rows="3" id="MAIL-text" placeholder="Input PLAIN message here..."></textarea>')

    $('#MAILbody').append('<div id="MAIL-progress"></div>')


    $('#MAILfooter').append('<button type="button" id="MAILTestbutton" class="btn dialogs-footerbutton">Test</button>')
    $('#MAILfooter').append('<button type="button" id="MAILOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">Send</button>')


    $('#MAILclosebutton').click(function(event) {
        if (interval) clearInterval(interval)
        interval = null;
        $('#MAIL').modal('hide');
    });
    $('#MAILOKbutton').on('click', function(event) {

        const ref = collection(db,'mail')
        addDoc(ref,{
            to: theNumber,
            message: {
                text: $('#MAIL-text').val(),
                html: $('#MAIL-html').val().replaceAll('\n','<br>'),
                subject: $('#MAIL-subject').val(),
            },
            created: serverTimestamp()
        })
        .then(docRef => {
            checknumber = docRef.id;
            $('#MAIL-progress').html('MAIL to ' + theNumber + ' saved to database')
        })
        .catch(error => {
            Message(error)
        })

        
    });
    $('#MAILTestbutton').on('click', function(event) {

        const ref = collection(db,'mail')
        addDoc(ref,{
            to: TESTNUMBER,
            message: {
                text: $('#MAIL-text').val(),
                html: $('#MAIL-html').val().replaceAll('\n','<br>'),
                subject: $('#MAIL-subject').val(),
            },
            created: serverTimestamp()
        })
        .then(docRef => {
            checknumber = docRef.id;
            $('#MAIL-progress').html('Test MAIL to ' + TESTNUMBER + ' saved to database')
        })
        .catch(error => {
            Message('Could not seve to database<br>' + error)
        })
    });
}

function check(){
    if (!checknumber) return;

    
    const checkRef = doc(db, 'mail', checknumber)
    getDoc(checkRef)
    .then(snapshot => {
        if (!snapshot.exists()) throw('Checknumber does not exist: ' + checknumber)
        const data = snapshot.data();
        if (data.delivery){
            if (data.delivery.state == "SUCCESS"){
                $('#MAIL-progress').html('MAIL to ' + data.to + ' was send with status ' + data.delivery.state)
                checknumber = null;
            }
            else if (data.delivery.state == "ERROR"){
                $('#MAIL-progress').html('MAIL to ' + data.to + ' was send with status ' + data.delivery.state + '<br><br>' + data.delivery.error)
                checknumber = null;
            }else{
                $('#MAIL-progress').html('MAIL to ' + data.to + ' has status status ' + data.delivery.state)
            }
        }
    })
    .catch(error => {
        console.error('Checking for MAIL delivery: ' + error)
    })
}
export function newMAIL(number){
    theNumber = number;
    checknumber = null;
    interval = setInterval(check,5000);
    $('#MAIL-progress').html('Ready to send MAIL')
    $('#MAIL').modal('show')
}