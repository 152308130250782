console.log('index.js loaded')

import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.esm.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/bootstrap.css'
import 'iconify-icon';
import Chart from 'chart.js/auto'
import {GoogleCharts} from 'google-charts';
require('moment-precise-range-plugin');
import moment from 'moment';
import { onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, serverTimestamp, where,toDate, getDocFromServer, onSnapshot, getCountFromServer, Timestamp } from "firebase/firestore";
import { auth, db, doSignIn } from "./components/account/account.js";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/animations/scale.css';

tippy.setDefaultProps({
    delay: 0,
    arrow: true,
    animation: 'scale',
    touch: 'hold',
});

import './components/colors.css';
import './index.css'

import LOGO from './images/raketten.svg'

import { closeSettings, iOS, openSettings } from "./components/settings/settings";
import { Message } from "./components/Message/message";
import { getDateString, getFlag, getShortDateString } from "./components/international/international";
import { notifyUser } from "./components/util/notification";
import { newSMS } from "./sms";

import NEWSLETTER from '../newsletter.raw'
import { sendemails } from "./sendemails";
import { newMAIL } from "./mail";
import { newNOTIFY } from "./notification";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions()
const deleteUser = httpsCallable(functions, 'deleteUser');

const geooptions = {
    width: $(window).width(),
    height: $(window).height() - 200,
    keepAspectRatio: true,
    datalessRegionColor: '#fafafa',
    backgroundColor: '#fff',
    defaultColor: '#000',
    colorAxis: {colors: ['#e1dd72', '#a8c66c', '#1b6535']},
};

$('body').append('<div id="app-spinner" class="spinner-border loading-spinner hidden" role="status"><span class="visually-hidden">Loading...</span></div>')
//Landing page
$('body').append('<div id="landing-page" class="screen beforelogin"></div>');{
    $('#landing-page').append('<div id="landing-page-version">' + BUILD_INFO.time + '</div>')

    $('#landing-page').append('<div class="title">Raketten</div>')

    $('#landing-page').append('<img id="logo" class="logo" src="' + LOGO + '">')

    $('#landing-page').append('<div class="subtitle">www.raketten.net</div>')

    $('#landing-page').append('<button id="signin-button" type="button" class="btn btn-raketten">Sign In</button>');
    $('#landing-page').append('<div id="greeting" class="hidden"></div>')
    $('#signin-button').on('click', function(){
        doSignIn()
    })
}
$('body').append('<div id="app-page" class="afterlogin hidden"></div>');{
}
//Main navigation bar
$('body').append('<nav id="app-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-bottom  afterlogin hidden raketten-navbar"></nav>');{
    $('#app-navbar').append('<div id="app-navbar-content" class="navbar-content"></div>');{
        $('#app-navbar-content').append('<div id="raketten-icon" class="raketten-icon main-icon"><iconify-icon icon="noto:rocket"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="qrcode-icon" class="raketten-icon main-icon"><iconify-icon icon="bx:qr"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="bowling-icon" class="raketten-icon main-icon"><iconify-icon icon="fxemoji:bowling"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="care-icon" class="raketten-icon main-icon"><iconify-icon icon="carbon:license-maintenance-draft"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="quiztrip-icon" class="raketten-icon main-icon"><iconify-icon icon="icon-park:gps"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="puzzle-icon" class="raketten-icon main-icon"><iconify-icon icon="noto:puzzle-piece"></iconify-icon></div>')
        $('#app-navbar-content').append('<div id="settings-icon" class="raketten-icon main-icon"><iconify-icon icon="flat-color-icons:settings"></iconify-icon></div>')
    }

    
    tippy('#raketten-icon', {
        content: 'Statistics for *.raketten.net',
    });
    tippy('#qrcode-icon', {
        content: 'Statistics for qr.raketten.net',
    });
    tippy('#bowling-icon', {
        content: 'Statistics for bowling.raketten.net',
    });
    tippy('#care-icon', {
        content: 'Statistics for care.raketten.net',
    });
    tippy('#quiztrip-icon', {
        content: 'Statistics for quiztrip.raketten.net',
    });
    tippy('#settings-icon', {
        content: 'Settings section',
    });

    $('.main-icon').on('click', function(evt){
        $('.main-icon').removeClass('raketten-icon-selected')
        $(evt.currentTarget).addClass('raketten-icon-selected')
        $('#app-page').empty();
        closeSettings();
    })


    $('#raketten-icon').on('click', function(evt){
        startRaketten();
    })
    $('#qrcode-icon').on('click', function(evt){
        startQR();
    })
    $('#bowling-icon').on('click', function(evt){
        startBowling()
    })
    $('#care-icon').on('click', function(evt){
        startCare();
    })
    $('#quiztrip-icon').on('click', function(evt){
        startQuizTrip();
    })
    $('#puzzle-icon').on('click', function(evt){
        startPuzzle();
    })
    $('#settings-icon').on('click', function(evt){
        openSettings();
    })
}

$('#logo').on('click', function(){
    $('.beforelogin').addClass('hidden');
    $('.afterlogin').removeClass('hidden');
    if (!isAdmin) window.location = 'https://www.raketten.net'
    else{
        GoogleCharts.load(showDashboard, {
            'packages': ['geochart','corechart'],
            'mapsApiKey': process.env.GOOGLEMAPAPIKEY
        });    
    }     
})
var isAdmin = false;
var usersubscription = null;
function start(){
    stop();

    const userRef = doc(db, 'users',auth.currentUser.uid);
    usersubscription = onSnapshot(userRef,{ includeMetadataChanges: true }, snapshot => doUser(snapshot))
}

function doUser(snapshot){
    if (snapshot.metadata.hasPendingWrites) return;
    if (!snapshot.exists()) return;

    const data = snapshot.data();

    if (data.isAdmin == true) {
        isAdmin = true;
        $('#logo').trigger('click')
        if (usersubscription != null) usersubscription();
        usersubscription = null;
    }else{
        $('#greeting').removeClass('hidden')
        let name = "Stranger";
        if (auth.currentUser.displayName) name = auth.currentUser.displayName;
        else if (auth.currentUser.email) name = auth.currentUser.email;
        else if (auth.currentUser.phoneNumber) name = auth.currentUser.phoneNumber;
        $('#greeting').html('Welcome ' + name)
    }
}
function stop(){
    $('#signin-button').removeClass('hidden')
    $('#greeting').addClass('hidden')
    isAdmin = false;
    if (usersubscription != null) usersubscription();
    usersubscription = null;
}

onAuthStateChanged(auth, async user => {
    if (user) {
        start();
    } else {
        stop();
        $('.afterlogin').addClass('hidden');
        $('.beforelogin').removeClass('hidden');
    }
});
function showDashboard(){
}

function startRaketten(){
    $('#app-page').empty();
    $('#app-spinner').removeClass('hidden')

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="trash-icon" class="raketten-icon admin-icon hidden"><iconify-icon icon="ph:trash-bold"></iconify-icon></div>')
        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })
        $('#trash-icon').on('click', function(){
            $('#admin-trashcan-container').removeClass('hidden')
        })

    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-communication-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }
    $('#app-page').append('<div id="admin-trashcan-container" class="admin-container hidden"></div>');{
    }


    const today = moment() 


    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];

    let geo = new Map();


    let promises = [];
        
    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, orderBy('created','asc'))
    promises.push(getDocs(usersQuery))

    const mailsRef = collection(db,'mail');
    const mailsQuery = query(mailsRef)
    promises.push(getDocs(mailsQuery))

    const messagesRef = collection(db,'messages');
    const messagesQuery = query(messagesRef)
    promises.push(getDocs(messagesQuery))

    const notificationsRef = collection(db,'notifications');
    const notificationsQuery = query(notificationsRef)
    promises.push(getDocs(notificationsQuery))

    Promise.all(promises)
    .then(results => {

        let deletedAccounts = new Map();

        results[0].forEach(user => {
            let data = user.data();

            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let cnt = geo.get(data.location.country) || 0;
                cnt++;
                geo.set(data.location.country,cnt);
            }
            else if (data.country){
                let cnt = geo.get(data.country) || 0;
                cnt++;
                geo.set(data.country,cnt);

            }

            if (data.deletiondate && (data.lastlogin < data.deletiondate)){
                data.daysdeleted = today.diff(moment(data.deletiondate.toDate()), 'days');
                deletedAccounts.set(user.id,data)
            }
        })

        if (deletedAccounts.size > 0){
            $('#trash-icon').removeClass('hidden')
            $('#admin-trashcan-container').append('<table id="trashcan-table" class="table align-middle admin-table"></table>')
            $('#trashcan-table').append('<thead style="text-align:center">\
                            <th style="text-align:start" scope="col">ID</th>\
                            <th scope="col">Name</th>\
                            <th scope="col">Friends</th>\
                            <th scope="col">Ratings</th>\
                            <th scope="col">Reviews</th>\
                            <th scope="col">Master Posts</th>\
                            <th scope="col">Games</th>\
                            <th scope="col">Timeline</th>\
                            <th scope="col">SupportThreads</th>\
                            <th scope="col">Reason</th>\
                            <th scope="col">Comment</th>\
                            <th scope="col">Days</th>\
                            </thead>')
            $('#trashcan-table').append('<tbody style="text-align:center" id="trashcan-table-body"></tbody>')


            let sorted = new Map([...deletedAccounts.entries()].sort((a, b) => b[1].daysdeleted - a[1].daysdeleted));

            for (let [key, value] of sorted) {

                let promises = [];

                const friends1Query = query(collection(db,'bowlingfriends'), where('user1','==',key))
                promises.push(getCountFromServer(friends1Query))
                const friends2Query = query(collection(db,'bowlingfriends'), where('user2','==',key))
                promises.push(getCountFromServer(friends2Query))
                const ratingsQuery = query(collection(db,'ratings'), where('owner','==',key))
                promises.push(getCountFromServer(ratingsQuery))
                const reviewsQuery = query(collection(db,'reviews'), where('owner','==',key))
                promises.push(getCountFromServer(reviewsQuery))
                const postsQuery = query(collection(db,'posts'), where('owner','==',key))
                promises.push(getCountFromServer(postsQuery))
                const gamesQuery = query(collection(db,'users',key,'bowlinggames'))
                promises.push(getCountFromServer(gamesQuery))
                const timelinesQuery = query(collection(db,'users',key,'posts'))
                promises.push(getCountFromServer(timelinesQuery))
                const bowlingSupport = doc(db,'supports','1:692996812647:web:105441cc44cee29b7489c2','cases',key)
                promises.push(getDoc(bowlingSupport))
                const careSupport = doc(db,'supports','1:692996812647:web:d9e724de772a9c6f7489c2','cases',key)
                promises.push(getDoc(careSupport))
                const userRecord = doc(db,'users',key)
                promises.push(getDoc(userRecord))

                
                Promise.all(promises)
                .then(results => {
                    let friendships = 0, ratings=0, reviews=0, masterposts=0,games=0,timelines=0,supportthreads=0, name = "Unknown";
                    if (results.length > 0) friendships += results[0].data().count;
                    if (results.length > 1) friendships += results[1].data().count;
                    if (results.length > 2) ratings += results[2].data().count;
                    if (results.length > 3) reviews += results[3].data().count;
                    if (results.length > 4) masterposts += results[4].data().count;
                    if (results.length > 5) games += results[5].data().count;
                    if (results.length > 6) timelines += results[6].data().count;
                    if (results.length > 7) if (results[7].exists()) supportthreads++;
                    if (results.length > 8) if (results[8].exists()) supportthreads++;
                    if (results.length > 9){
                        if (results[9].exists()) {
                            const data = results[9].data();
                            if (data.displayname != null && data.displayname != "") name = data.displayname;
                            else if (data.email != null && data.email != "") name = data.email;
                            else if (data.phone != null && data.phone != "") name = data.phone;
                        }  
                    } 

                    let reason = "";
                    if (value.leaving && value.leaving.reason) reason = value.leaving.reason
                    let comment = "";
                    if (value.leaving && value.leaving.comment) comment = value.leaving.comment

                    
                    $('#trashcan-table-body').append('<tr class="trashcanrow">\
                                                <td style="text-align:start">' +  key + '</td>\
                                                <td>' +  name + '</td>\
                                                <td>' +  friendships + '</td>\
                                                <td>' +  ratings + '</td>\
                                                <td>' +  reviews + '</td>\
                                                <td>' +  masterposts + '</td>\
                                                <td>' +  games + '</td>\
                                                <td>' +  timelines + '</td>\
                                                <td>' +  supportthreads + '</td>\
                                                <td>' +  reason + '</td>\
                                                <td>' +  comment + '</td>\
                                                <td>' + value.daysdeleted + '</td>\
                                                </tr>');
                })
            }

        }
        
        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)

        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));
        
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        geo.forEach(function(value, country) {
            geochartdata.addRows([[country, value]]);
        })

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);


        let mailsuccess = [0,0,0,0,0,0];
        let mailerror = [0,0,0,0,0,0];

        results[1].forEach(mail => {
            const data = mail.data();
            if (data.delivery && (data.delivery.endTime != null) && (data.delivery.state != null) ){
                const idx = 5-moment.preciseDiff(moment(data.delivery.endTime.toDate()),today,true).months;
                if (idx > 0){
                    if (data.delivery.state == "SUCCESS") mailsuccess[idx]++
                    else {
                        mailerror[idx]++
                    }
                }
            }
        })


        
        let smssuccess = [0,0,0,0,0,0];
        let smserror = [0,0,0,0,0,0];

        results[2].forEach(sms => {
            const data = sms.data();
            if (data.delivery && (data.delivery.endTime != null) && (data.delivery.state != null) ){
                const idx = 5-moment.preciseDiff(moment(data.delivery.endTime.toDate()),today,true).months;
                if (idx > 0){
                    if (data.delivery.state == "SUCCESS") smssuccess[idx]++
                    else smserror[idx]++
                }
            }
        })

        let notificationssuccess = [0,0,0,0,0,0];
        let notificationserror = [0,0,0,0,0,0];
        
        results[3].forEach(notification => {
            const data = notification.data();
            const idx = 5-moment.preciseDiff(moment(data.created.toDate()),today,true).months;
            if (idx > 0) {
                notificationssuccess[idx] += data.count
                notificationserror[idx] += data.failure
            }
        })
        

        const communicationdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: '#ca3c6980',
                    borderColor: '#ca3c69ff',
                    borderWidth: 1,
                    label: 'Mail Failed',
                    data: mailerror,
                },
                {
                    backgroundColor: '#ca3c6940',
                    borderColor: '#ca3c69ff',
                    borderWidth: 1,
                    label: 'Mails succeeded',
                    data: mailsuccess,
                },
                {
                    backgroundColor: '#69ca3c80',
                    borderColor: '#69ca3cff',
                    borderWidth: 1,
                    label: 'SMS Failed',
                    data: smserror,
                },
                {
                    backgroundColor: '#69ca3c40',
                    borderColor: '#69ca3cff',
                    borderWidth: 1,
                    label: 'SMS succeeded',
                    data: smssuccess,
                },
                {
                    backgroundColor: '#3c69ca80',
                    borderColor: '#3c69caff',
                    borderWidth: 1,
                    label: 'Notifications Failed',
                    data: notificationserror,
                },
                {
                    backgroundColor: '#3c69ca40',
                    borderColor: '#3c69caff',
                    borderWidth: 1,
                    label: 'Notifications succeeded',
                    data: notificationssuccess,
                }
            ]
        };
        const communicationconfig = {
            type: 'bar',
            data: communicationdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Communications to Users'
                    },
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            }
        };
        let communicationChart = new Chart(document.getElementById('admin-communication-development'),communicationconfig)


        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
}


function startQR(){
    $('#app-page').empty();
    $('#app-spinner').removeClass('hidden')

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })

    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }


    const today = moment() 

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, where('apps', 'array-contains','1:692996812647:web:abdb0900f9d6e1047489c2'), orderBy('created','asc'))

    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];

    let geo = new Map();

    getDocs(usersQuery)
    .then(snapshot => {



        snapshot.forEach(user => {
            const data = user.data();

            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let cnt = geo.get(data.location.country) || 0;
                cnt++;
                geo.set(data.location.country,cnt);
            }
            else if (data.country){
                let cnt = geo.get(data.country) || 0;
                cnt++;
                geo.set(data.country,cnt);

            }

        })

        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)


        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));

        
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        geo.forEach(function(value, country) {
            geochartdata.addRows([[country, value]]);
        })

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);


        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
        })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })

}
function startBowling(){
    $('#app-spinner').removeClass('hidden')
    $('#app-page').empty();

    $('#app-page').append('<div id="bowling-light-container" class="admin-container"></div>')

    const today = moment()

    let users = [];
    let centers;
    let friends;
    let spares;
    let totalusers,totalcenters,totalfriends,totalspares;
    let newUsers = 0;

    let promises = [];


    const onedayago = moment(Date.now()).subtract(24, 'hours').toDate();

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, where('lastlogin', '>', Timestamp.fromDate(onedayago)), orderBy('lastlogin','desc'))
    promises.push(getDocs(usersQuery));

    promises.push(getCountFromServer(query(collection(db,'bowlingcenters'),  where('created', '>', Timestamp.fromDate(onedayago)))))
    promises.push(getCountFromServer(query(collection(db,'bowlingfriends'),  where('created', '>', Timestamp.fromDate(onedayago)))))
    promises.push(getCountFromServer(query(collection(db,'spares'),  where('created', '>', Timestamp.fromDate(onedayago)))))

    promises.push(getCountFromServer(query(collection(db,'users'),  where('apps', 'array-contains', '1:692996812647:web:105441cc44cee29b7489c2'))))
    promises.push(getCountFromServer(query(collection(db,'bowlingcenters'))))
    promises.push(getCountFromServer(query(collection(db,'bowlingfriends'))))
    promises.push(getCountFromServer(query(collection(db,'spares'))))

    Promise.all(promises)
    .then(results => {

        let promises = [];

        results[0].forEach(user => {
            const data = user.data();
            if (data.apps.includes('1:692996812647:web:105441cc44cee29b7489c2')){
                users.push(data)

                if (data.created.toDate() > onedayago) newUsers++; 

                const gamesRef = collection(db,'users',user.id,'bowlinggames')
                const gamesQuery = query(gamesRef,  where('created', '>', Timestamp.fromDate(onedayago)))
                promises.push(getCountFromServer(gamesQuery))    
            }
        })
        centers = results[1].data().count
        friends =results[2].data().count
        spares = results[3].data().count
        totalusers = results[4].data().count
        totalcenters = results[5].data().count
        totalfriends = results[6].data().count
        totalspares = results[7].data().count

        return Promise.all(promises)
    })
    .then(results => {
        let totalgames = 0;
        for (let i=0; i < results.length; i++) {
            const usergames = results[i].data().count;
            users[i].games = usergames;
            totalgames += usergames;
        }

        $('#bowling-light-container').append('<div id="bowling-light-table"></div>')
        $('#bowling-light-table').append('<div id="bowlig-light-table-summary"></div>');{
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + users.length + ' <br>Online</div>')
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + newUsers + ' (' + totalusers + ')<br>New Users</div>')
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + totalgames + ' <br>Games</div>')
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + centers + ' (' + totalcenters + ')<br>Centers</div>')
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + spares + ' (' + totalspares + ')<br>Spares</div>')
            $('#bowlig-light-table-summary').append('<div class="bowlig-light-table-summary-entry">' + friends + ' (' + totalfriends + ')<br>Friends</div>')
        }

        for (let i=0; i < users.length; i++) {
            const user = users[i]

            let comm = "";
            if (user.newtokens && user.newtokens['1:692996812647:web:105441cc44cee29b7489c2'] != null) comm += '<iconify-icon icon="iconamoon:notification-fill"></iconify-icon>'
            if (user.email != null) comm += '<iconify-icon icon="ic:baseline-email"></iconify-icon>'
            if (user.phone != null) comm += '<iconify-icon icon="material-symbols:smartphone"></iconify-icon>'
            else if (user.phoneNumber != null) comm += '<iconify-icon icon="material-symbols:smartphone"></iconify-icon>'
            if (user.installed == true) comm += '<iconify-icon icon="material-symbols:smartphone"></iconify-icon>'
            if (user.installed == false) comm += '<iconify-icon style="color:red" icon="material-symbols:smartphone"></iconify-icon>'
            if (user.operatingsystem == 'iOS') comm += '<iconify-icon icon="openmoji:apple"></iconify-icon>'
            if (user.operatingsystem == 'Android') comm += '<iconify-icon icon="openmoji:android"></iconify-icon>'
            if (user.operatingsystem == 'Windows') comm += '<iconify-icon icon="openmoji:windows"></iconify-icon>'

            let country = user.country;
            if (user.location && user.location.country) country = user.location.country

            let name = '';
            if (user.displayname) name = user.displayname;
            else if (user.email) name = user.email;
            else if (user.phone) name = user.phone;
            else if (user.phoneNumber) name = user.phoneNumber;

            comm += 'v' + user.visits;
            comm += 'g' + user.games;

            $('#bowling-light-table').append('<div class="bowling-light-table-entry" id="bowling-light-table-entry' + i + '"></div>');{
                $('#bowling-light-table-entry' + i).append('<div title="' + country + '">' + getFlag(country) + '</div>')
                $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-name">' +  name + '</div>')

                if (user.installed == true) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="material-symbols:apps"></iconify-icon></div>')
                else if (user.installed == false) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="color:red" icon="material-symbols:apps"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden" icon="material-symbols:apps"></iconify-icon></div>')

                if (user.email != null) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="ic:baseline-email"></iconify-icon></div>')
                else  if (user.phone != null) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="fa6-solid:comment-sms"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden;" icon="ic:baseline-email"></iconify-icon></div>')

                
                if (user.newtokens && user.newtokens['1:692996812647:web:105441cc44cee29b7489c2'] != null) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="iconamoon:notification-fill"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden" icon="iconamoon:notification-fill"></iconify-icon></div>')

                if (user.games > 0) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="fxemoji:bowling"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden" icon="fxemoji:bowling"></iconify-icon></div>')

                if (user.visits > 1) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="vaadin:user-star"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden" icon="vaadin:user-star"></iconify-icon></div>')

                if (user.operatingsystem == 'iOS') $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="openmoji:apple"></iconify-icon></div>')
                else if (user.operatingsystem == 'Android') $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="devicon:android"></iconify-icon></div>')
                else if (user.operatingsystem != null) $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon icon="ic:twotone-desktop-mac"></iconify-icon></div>')
                else $('#bowling-light-table-entry' + i).append('<div class="bowling-light-table-entry-icon"><iconify-icon style="visibility:hidden" icon="ic:twotone-desktop-mac"></iconify-icon></div>')
            }
        }

        $('#bowling-light-table').append('<button class="btn btn-outline-secondary" type="button" id="full-bowling-report-button">Full Report...</button>')

        $('#full-bowling-report-button').on('click', function(){
            startBowlingDetail()
        })

        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
}

function startBowlingDetail(){


    $('#app-spinner').removeClass('hidden')
    $('#app-page').empty();

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="users-icon" class="raketten-icon admin-icon"><iconify-icon icon="gis:globe-users"></iconify-icon></div>')

            tippy('#graphs-icon', {
                content: 'Graphs for the bowling.raketten.net...',
            });
            tippy('#map-icon', {
                content: 'Map to show location of all users',
            });
            tippy('#users-icon', {
                content: 'List of all users, sorted after last login and option to send News Letter to all of them',
            });

        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })
        $('#users-icon').on('click', function(){
            $('#admin-user-container').removeClass('hidden')
        })

    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-games-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-players-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-centers-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-friendships-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-spares-development" class="admin-chart"></canvas>')
        

        $('#admin-graphs-container').append('<div id="admin-countries-container" class="admin-chart piechart-container"></div>');{
            $('#admin-countries-container').append('<div id="admin-countries" style="width: 300px; height: 180px"></div>')
            $('#admin-countries-container').append('<div id="countrypieholetext" class="pieholetext twoliner"></div>')
        }

        $('#admin-graphs-container').append('<div id="admin-contacttype-container" class="admin-chart piechart-container"></div>');{
            $('#admin-contacttype-container').append('<div id="admin-contacttype" style="width: 300px; height: 180px"></div>');
            $('#admin-contacttype-container').append('<div class="pieholetext">Accounts</div>')
        }

        $('#admin-graphs-container').append('<div id="admin-installation-container" class="admin-chart piechart-container"></div>');{
            $('#admin-installation-container').append('<div id="admin-installation" style="width: 300px; height: 180px"></div>');
            $('#admin-installation-container').append('<div class="pieholetext">Installation</div>')
        }

        $('#admin-graphs-container').append('<div id="admin-OS-container" class="admin-chart piechart-container"></div>');{
            $('#admin-OS-container').append('<div id="admin-OS" style="width: 300px; height: 180px"></div>');
            $('#admin-OS-container').append('<div class="pieholetext">OS</div>')
        }

        $('#admin-graphs-container').append('<div id="admin-browser-container" class="admin-chart piechart-container"></div>');{
            $('#admin-browser-container').append('<div id="admin-browser" style="width: 300px; height: 180px"></div>');
            $('#admin-browser-container').append('<div class="pieholetext">Browser</div>')
        }

        tippy('#admin-users-development', {
            content: 'Graph showing accumulative number of users over past 180 days',
        });
        tippy('#admin-games-development', {
            content: 'Graph showing accumulative number of games over past 180 days',
        });
        tippy('#admin-players-development', {
            content: 'Graph showing number of active users over past 180 days',
        });
        tippy('#admin-login-development', {
            content: 'Graph showing number of logins over past 180 days',
        });
        tippy('#admin-centers-development', {
            content: 'Graph showing accumulative number of centers over past 180 days',
        });
        tippy('#admin-friendships-development', {
            content: 'Graph showing accumulative number of friendships over past 180 days',
        });
        tippy('#admin-spares-development', {
            content: 'Graph showing accumulative number of spare training shots over past 180 days',
        });
        tippy('#admin-countries', {
            content: 'Graph showing users per country',
        });
        tippy('#admin-contacttype', {
            content: 'Graph showing users per contact type',
        });
        tippy('#admin-installation', {
            content: 'Graph showing users installed/not installed',
        });
        tippy('#admin-OS', {
            content: 'Graph showing operating systems in use',
        });
        tippy('#admin-browser', {
            content: 'Graph showing browsers in use',
        });

    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }
    $('#app-page').append('<div id="admin-user-container" class="admin-container hidden"></div>');{
    }

    const today = moment()


    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];
    let games = [0,0,0,0,0,0];
    let players = [0,0,0,0,0,0];
    let centers = [0,0,0,0,0,0];
    let friendships = [0,0,0,0,0,0];
    let spares = [0,0,0,0,0,0];
    let com = [0,0,0];//email, none, phone,
    let installed=0;
    let notinstalled=0;
    let ios=0;
    let notios=0;

    let geo = new Map();
    let members = []

    let users = new Map;

    let usergames = new Map();
    let latestgame = new Map();
    let OSs = new Map();
    let browsers = new Map()

    let promises = [];

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef,where('apps', 'array-contains','1:692996812647:web:105441cc44cee29b7489c2'), orderBy('created','asc'))
    promises.push(getDocs(usersQuery));

    promises.push(getDocs(query(collection(db,'bowlingcenters'))))
    promises.push(getDocs(query(collection(db,'bowlingfriends'))))
    promises.push(getDocs(query(collection(db,'spares'))))

    Promise.all(promises)
    .then(results => {
        let promises = [];

        results[0].forEach(user => {
            const data = user.data();

            latestgame.set(user.id, " ")

            const gamesRef = collection(db,'users',user.id,'bowlinggames')
            const gamesQuery = query(gamesRef, orderBy('created','asc'))
            promises.push(getDocs(gamesQuery))

            
            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            members.push(user.id)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let country = geo.get(data.location.country);
                if (country == null){
                    let elem = {
                        count: 1,
                        cities: new Map(),
                        regions: new Map()
                    }

                    if (data.location.city) elem.cities.set(data.location.city, 1)
                    if (data.location.region) elem.regions.set(data.location.region, 1)

                    geo.set(data.location.country,elem)
                }else{
                    country.count++;
                    if (data.location.city){
                        let city = country.cities.get(data.location.city)
                        if (city == null) country.cities.set(data.location.city,1)
                        else country.cities.set(data.location.city,city+1)
                    }
                    if (data.location.region){
                        let region = country.regions.get(data.location.region)
                        if (region == null) country.regions.set(data.location.region,1)
                        else country.regions.set(data.location.region,region+1)
                    }
                    geo.set(data.location.country,country);
                }
            }
            else if (data.country){
                let country = geo.get(data.country);
                if (country == null){
                    let elem = {
                        count: 1,
                        cities: new Map(),
                        regions: new Map()
                    }

                    if (data.city) elem.cities.set(data.city, 1)

                    geo.set(data.country,elem)
                }else{
                    country.count++;
                    if (data.city){
                        let city = country.cities.get(data.city)
                        if (city == null) country.cities.set(data.city,1)
                        else country.cities.set(data.city,city+1)
                    }
                    geo.set(data.country,country);
                }
            }else{
                let country = geo.get('Unknown');
                if (country == null){
                    geo.set('Unknown',{count: 1})
                }else{
                    country.count++;
                    geo.set('Unknown',country);
                }
            }

            data.days = today.diff(moment(data.lastlogin.toDate()), 'days');

            if (data.email && (data.email != "")) com[0]++;
            else if (data.phone && (data.phone != "")) com[2]++;
            else com[1]++;

            users.set(user.id,data)

            if (data.installed == true) installed++;
            if (data.installed == false) notinstalled++;

            if (data.operatingsystem){
                let theOS = OSs.get(data.operatingsystem);
                if (theOS == null) OSs.set(data.operatingsystem,1)
                else OSs.set(data.operatingsystem,theOS+1)
            }
            if (data.browser){
                let browser = browsers.get(data.browser);
                if (browser == null) browsers.set(data.browser,1)
                else browsers.set(data.browser,browser+1)
            }
        })

        results[1].forEach(center => {
            if (center.data().approved)
                for (let z=Math.max(0,5-moment.preciseDiff(moment(center.data().created.toDate()),today,true).months); z < 6; z++) centers[z]++;
        })
        results[2].forEach(friendship => {
            for (let z=Math.max(0,5-moment.preciseDiff(moment(friendship.data().created.toDate()),today,true).months); z < 6; z++) friendships[z]++;
        })
        results[3].forEach(spare => {
            let z= 5 - moment.preciseDiff(moment(spare.data().created.toDate()),today,true).months; 
            if (z > 0) spares[z]++;
        })

        return Promise.all(promises)
    })
    .then(results => {
        for (let i=0; i < results.length; i++){
            const snapshot = results[i];
            let active = [false,false,false,false,false,false];
    
            usergames.set(members[i],snapshot.size)

            snapshot.forEach(game => {
                const data = game.data();

                const created = moment(data.created.toDate())
                const diff1 = moment.preciseDiff(created,today,true)

                const idx = 5-diff1.months;

                for (let z=Math.max(0,idx); z < 6; z++)
                    games[z]++;

                if (idx> 0) active[idx] = true;

                if (snapshot.size > 1) latestgame.set(members[i], today.diff(created, 'days'))
            })

            for (let j = 0; j < 6; j++) if (active[j]) players[j]++;
        }

 
        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)

        const gamesdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Games',
                    data: games,
                },
            ]
        };
        const gamesconfig = {
            type: 'bar',
            data: gamesdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Games'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let gamesChart = new Chart(document.getElementById('admin-games-development'),gamesconfig)

        const playersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Players',
                    data: players,
                },
            ]
        };
        const playersconfig = {
            type: 'bar',
            data: playersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Players'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let playersChart = new Chart(document.getElementById('admin-players-development'),playersconfig)


        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const centersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Centers',
                    data: centers,
                },
            ]
        };
        const centersconfig = {
            type: 'bar',
            data: centersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Centers'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let centersChart = new Chart(document.getElementById('admin-centers-development'),centersconfig)

        const friendshipsdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Friendships',
                    data: friendships,
                },
            ]
        };
        const friendshipsconfig = {
            type: 'bar',
            data: friendshipsdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Friendships'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let friendshipsChart = new Chart(document.getElementById('admin-friendships-development'),friendshipsconfig)

        const sparesdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Spareshot',
                    data: spares,
                },
            ]
        };
        const sparesconfig = {
            type: 'bar',
            data: sparesdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Spare shots'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let sparesChart = new Chart(document.getElementById('admin-spares-development'),sparesconfig)

        let countriesChart = new GoogleCharts.api.visualization.PieChart(document.getElementById('admin-countries'))
        let comChart = new GoogleCharts.api.visualization.PieChart(document.getElementById('admin-contacttype'))
        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));
        
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        let totalgeo = 0;
        geo.forEach(function(value, country) {
            totalgeo += value.count;
            geochartdata.addRows([[country, value.count]]);
        })

        $('#countrypieholetext').html(geo.size + '<br>Countries')

        let theRegion = "";
        google.visualization.events.addListener(mapchart, 'select', function () {
            if (theRegion == "") return;
            var selection = mapchart.getSelection();
            if (selection.length > 0) {
                const country = geochartdata.getValue(selection[0].row, 0);
                const count = geochartdata.getValue(selection[0].row, 1);

                geochartdata = new GoogleCharts.api.visualization.DataTable();
                geochartdata.addColumn('string', 'City');
                geochartdata.addColumn('number', 'count');

                geo.forEach(function(value, cnt) {
                    if (country == cnt){
                        value.cities.forEach(function(cvalue, city){
                            geochartdata.addRows([[city, cvalue]]);
                        })
                        
                    }
                })

                geooptions.region = theRegion;
                geooptions.displayMode = 'markers'
                mapchart.draw(geochartdata, geooptions);
                theRegion = "";
            }
        });
        
        google.visualization.events.addListener(mapchart, 'regionClick', function (selection) {
            theRegion = selection.region
        });

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);


        var countrychartdata = new google.visualization.DataTable();
        countrychartdata.addColumn('string', 'Count');
        countrychartdata.addColumn('number', 'count');
        countrychartdata.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});

        geo.forEach(function(value, country) {
            const tooltip = '<div style="padding:5px;display:flex;flex-wrap:nowrap;gap:5px;color:black;"><div>' + getFlag(country) + '</div><div>' + parseInt((1000 * value.count)/totalgeo)/10 + '%</div><div>' + value.count + '</div></div>';
            countrychartdata.addRow([country, value.count,tooltip]);
        })

        countriesChart.draw(countrychartdata,{
            width: 300,
            height: 180,
            backgroundColor: 'transparent',
            chartArea: {
                left: 0,
                top: 0,
                width: 300,
                height: 180
            },
            legend: {
                position: 'labeled',
            },
            pieSliceText: 'none',
            pieSliceTextStyle: {
                color: 'black'
            },
            sliceVisibilityThreshold: .02,
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'none'
            },
            enableInteractivity: false,
            pieHole: 0.5,

        })


        var comchartdata = new google.visualization.DataTable();
        comchartdata.addColumn('string', 'Type');
        comchartdata.addColumn('number', 'Count');
        comchartdata.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});


        const comicon = ['<iconify-icon icon="ic:baseline-email"></iconify-icon>','<iconify-icon icon="solar:incognito-bold"></iconify-icon>', '<iconify-icon icon="fa6-solid:comment-sms"></iconify-icon>']
        const type = ['Email', 'Anonymous','Phone']
        com.forEach(function(val,idx) {
            const tooltip = '<div style="padding:5px;display:flex;flex-wrap:nowrap;gap:5px;color:black;"><div>' + comicon[idx] + '</div><div>' + parseInt((1000 * val)/users.size)/10 + '%</div><div>' + val + '</div></div>';
            comchartdata.addRow([type[idx], val,tooltip]);
        })
        comChart.draw(comchartdata,{
            width: 300,
            height: 180,
            backgroundColor: 'transparent',
            chartArea: {
                left: 0,
                top: 0,
                width: 300,
                height: 180
            },
            legend: {
                position: 'labeled',
            },
            pieSliceText: 'none',
            pieSliceTextStyle: {
                color: 'black'
            },
            //sliceVisibilityThreshold: .1,
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'none'
            },
            enableInteractivity: false,
            pieHole: 0.5,
        })


        let installationChart = new GoogleCharts.api.visualization.PieChart(document.getElementById('admin-installation'))
        var installationchartdata = new google.visualization.DataTable();
        installationchartdata.addColumn('string', 'Label');
        installationchartdata.addColumn('number', 'count');

        installationchartdata.addRow(['Installed', installed]);
        installationchartdata.addRow(['Not Installed', notinstalled]);

        installationChart.draw(installationchartdata,{
            width: 300,
            height: 180,
            backgroundColor: 'transparent',
            chartArea: {
                left: 0,
                top: 0,
                width: 300,
                height: 180
            },
            legend: {
                position: 'labeled',
            },
            pieSliceText: 'none',
            pieSliceTextStyle: {
                color: 'black'
            },
            sliceVisibilityThreshold: .02,
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'none'
            },
            enableInteractivity: false,
            pieHole: 0.5,

        })

        let OSChart = new GoogleCharts.api.visualization.PieChart(document.getElementById('admin-OS'))
        var OSchartdata = new google.visualization.DataTable();
        OSchartdata.addColumn('string', 'OS');
        OSchartdata.addColumn('number', 'count');

        OSs.forEach(function(count, theOS) {
            OSchartdata.addRow([theOS, count]);
        })

        OSChart.draw(OSchartdata,{
            width: 300,
            height: 180,
            backgroundColor: 'transparent',
            chartArea: {
                left: 0,
                top: 0,
                width: 300,
                height: 180
            },
            legend: {
                position: 'labeled',
            },
            pieSliceText: 'none',
            pieSliceTextStyle: {
                color: 'black'
            },
            sliceVisibilityThreshold: .02,
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'none'
            },
            enableInteractivity: false,
            pieHole: 0.5,

        })


        let browserChart = new GoogleCharts.api.visualization.PieChart(document.getElementById('admin-browser'))
        var browserchartdata = new google.visualization.DataTable();
        browserchartdata.addColumn('string', 'browser');
        browserchartdata.addColumn('number', 'count');

        browsers.forEach(function(count, thebrowser) {
            browserchartdata.addRow([thebrowser, count]);
        })

        browserChart.draw(browserchartdata,{
            width: 300,
            height: 180,
            backgroundColor: 'transparent',
            chartArea: {
                left: 0,
                top: 0,
                width: 300,
                height: 180
            },
            legend: {
                position: 'labeled',
            },
            pieSliceText: 'none',
            pieSliceTextStyle: {
                color: 'black'
            },
            sliceVisibilityThreshold: .05,
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'none'
            },
            enableInteractivity: false,
            pieHole: 0.5,

        })

        users[Symbol.iterator] = function* () {
            yield* [...this.entries()].sort((a, b) => b[1].lastlogin - a[1].lastlogin);
        }





        $('#admin-user-container').append('<div id="users-input-group" class="input-group mb-3"></div>');{
            $('#users-input-group').append('<span class="input-group-text" id="basic-addon1">@</span>')
            $('#users-input-group').append('<input id="newsletter-url" type="url" class="form-control" placeholder="Newsletter url" aria-label="Newsletter" aria-describedby="Newletter Url">')
            $('#users-input-group').append('<button class="btn btn-outline-secondary" type="button" id="test-button">Test...</button>')
            $('#users-input-group').append('<button class="btn btn-outline-secondary" type="button" id="send-button">Send...</button>')

            tippy('#basic-addon1', {
                content: 'This is mainly for decoration...',
            });
            tippy('#newsletter-url', {
                content: 'Paste the url to the news letter in here...',
            });
            tippy('#test-button', {
                content: 'This will send the newsletter to henrik@raketten.net and +886 909 261 952',
            });
            tippy('#send-button', {
                content: 'This will send the newsletter to the entire list below, unless they already unsubscribed',
            });

            $('#test-button').on('click',function(){
                const url = $('#newsletter-url').val();
                if (url != ""){

                    const basic = NEWSLETTER.replaceAll('{1}', url);

                    const userRef = collection(db,'users')
                    const supportQuery = query(userRef,where('isSupport','==',true))
                
                    getDocs(supportQuery)
                    .then(snapshot => {
                        let promises = [];
                        snapshot.forEach((supportuser) => {
                            const data = supportuser.data();
                            const unsubscribe = 'https://bowling.raketten.net?unsubscribe=1';
                            const simple = 'Hi There,\n\nThanks for using HR Bowling. We have just published a newsletter, please read it online, by clicking this link \n\n' + url + '\n\nThanks Henrik Rasmussen, HR Bowling\n\nUnsubscribe to these messages by clicking this link \n\n' + unsubscribe;

                            if ((data.email != null) && (data.email != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'mail'),{
                                    to: data.email,
                                    message:{
                                        subject: 'Newsletter from HR Bowling',
                                        html: basic.replaceAll('{2}', unsubscribe),
                                        text: simple,
                                    },
                                    created: serverTimestamp()
                                }))
                            }
                            if ((data.phone != null) && (data.phone != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'messages'),{
                                    to: data.phone,
                                    body: simple,
                                    created: serverTimestamp()
                                }))
                            }
                        });
                    })
                    .catch(error => {
                        console.error('Testing newsletter: ' + error)
                    })
                
                }
            })

            $('#send-button').on('click',function(){
                const url = $('#newsletter-url').val();
                if (url != ""){

                    const basic = NEWSLETTER.replaceAll('{1}', url);
                    const unsubscribe = 'https://bowling.raketten.net?unsubscribe=1';
                    const simple = 'Hi There,\n\nThanks for using HR Bowling. We have just published a newsletter, please read it online, by clicking this link\n\n' + url + '\n\nThanks Henrik Rasmussen, HR Bowling\n\nUnsubscribe to these messages by clicking this link \n\n' + unsubscribe;

                    const userRef = collection(db,'users')
                    const userQuery = query(userRef,where('apps', 'array-contains','1:692996812647:web:105441cc44cee29b7489c2'))

                
                    var emails = [];

                    getDocs(userQuery)
                    .then(snapshot => {
                        let promises = [];
                        snapshot.forEach((supportuser) => {
                            const data = supportuser.data();

                            if ((data.email != null) && (data.email != "") && (data.unsubscribed != true)){
                                if (!emails.includes(data.email)) emails.push(data.email);
                            }
                            if ((data.phone != null) && (data.phone != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'messages'),{
                                    to: data.phone,
                                    body: simple,
                                    created: serverTimestamp(),
                                    newsletter: url,
                                }))
                            }
                        });

                        if (emails.length) sendemails(emails,'Newsletter from HR Bowling',basic.replaceAll('{2}', unsubscribe),simple,url)
                    })
                    .catch(error => {
                        console.error('Sending newsletter: ' + error)
                    })
                
                }
            })

        }

        $('#admin-user-container').append('<table id="user-table" class="table align-middle admin-table"></table>')
        $('#user-table').append('<thead><th scope="col">L</th><th scope="col">G</th><th scope="col">Name</th><th scope="col">C</th><th scope="col">C</th><th scope="col">V</th><th scope="col">G</th></thead>')
        $('#user-table').append('<tbody id="user-table-body"></tbody>')
        for (let [key, value] of users) {


            var maillinktext = "mailto:?to=" + value.email + "&subject=Message from HR Bowling&body=" + getName(value)
            let comm = "";
            if (value.newtokens && value.newtokens['1:692996812647:web:105441cc44cee29b7489c2'] != null) comm += '<a href="#" id="notify' + key + '" style="cursor:pointer"><iconify-icon icon="iconamoon:notification-fill"></iconify-icon></a>'

            if (value.email != null) comm += '<a href="#" id="mail' + key + '" style="cursor:pointer"><iconify-icon icon="ic:baseline-email"></iconify-icon></a>'
            if (value.phone != null) comm += '<a href"#" id="sms' + key + '" style="cursor:pointer"><iconify-icon icon="fxemoji:cellphone"></iconify-icon></a>'
            else if (value.phoneNumber != null) comm += '<a href"#" id="sms' + key + '" style="cursor:pointer"><iconify-icon icon="fxemoji:cellphone"></iconify-icon></a>'

            if (value.installed == true) comm += '<iconify-icon icon="ic:baseline-apps"></iconify-icon>'
            if (value.installed == false) comm += '<iconify-icon style="color:red" icon="ic:baseline-apps"></iconify-icon>'
            if (value.operatingsystem == 'iOS') comm += '<iconify-icon icon="openmoji:apple"></iconify-icon>'
            if (value.operatingsystem == 'Android') comm += '<iconify-icon icon="devicon:android"></iconify-icon>'
            if (value.operatingsystem == 'Windows') comm += '<iconify-icon icon="openmoji:windows"></iconify-icon>'

            let country = value.country;
            if (value.location && value.location.country) country = value.location.country

            const days = today.diff(moment(value.lastlogin.toDate()), 'days');
            $('#user-table-body').append('<tr><td>' + value.days + '</th><td>' + latestgame.get(key) + '</th><td>' +  getName(value) + '</td><td>' + comm + '</td><td title="' + country + '">' + getFlag(country) + '</td><td>' + value.visits + '</td><td>' + usergames.get(key) + '</td></tr>');

            $('#sms' + key).on('click', function(){
                let number = null;
                if (value.phone != null) number = value.phone;
                else if (value.phoneNumber != null) number = value.phoneNumber
                if (number == null) return;
                newSMS(number)
            })
            $('#mail' + key).on('click', function(){
                let number = null;
                if (value.email != null) number = value.email;
                if (number == null) return;
                newMAIL(number) 
            })
            $('#notify' + key).on('click', function(){
                newNOTIFY(key, '1:692996812647:web:105441cc44cee29b7489c2','https://bowling.raketten.net', 'https://bowling.raketten.net/notification.png')
            })
        }

        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
}
function startCare(){
    $('#app-page').empty();
    $('#app-spinner').removeClass('hidden')

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="users-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:accounts-group"></iconify-icon></div>')

            tippy('#graphs-icon', {
                content: 'Graphs for the care.raketten.net...',
            });
            tippy('#map-icon', {
                content: 'Map to show location of all users',
            });
            tippy('#users-icon', {
                content: 'List of all users, sorted after last login and option to send News Letter to all of them',
            });

        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })
        $('#users-icon').on('click', function(){
            $('#admin-user-container').removeClass('hidden')
        })


    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-domains-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-assets-development" class="admin-chart"></canvas>')

    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }
    $('#app-page').append('<div id="admin-user-container" class="admin-container hidden"></div>');{
    }



    const today = moment() 

    let promises = [];

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, where('apps', 'array-contains','1:692996812647:web:d9e724de772a9c6f7489c2'), orderBy('created','asc'))
    promises.push(getDocs(usersQuery));

    promises.push(getDocs(query(collection(db,'domains'))))

    


    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];
    let domains = [0,0,0,0,0,0];
    let assets = [0,0,0,0,0,0];

    let geo = new Map();
    let users = new Map;


    Promise.all(promises)
    .then(results => {

        let Promises = [];

        results[0].forEach(user => {
            const data = user.data();

            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let cnt = geo.get(data.location.country) || 0;
                cnt++;
                geo.set(data.location.country,cnt);
            }
            else if (data.country){
                let cnt = geo.get(data.country) || 0;
                cnt++;
                geo.set(data.country,cnt);


            }

            data.days = today.diff(moment(data.lastlogin.toDate()), 'days');
            data.domains = 0;
            data.assets = 0;
            users.set(user.id,data)
        })

        results[1].forEach(domain => {
            const data = domain.data();
            for (let z=Math.max(0,5-moment.preciseDiff(moment(data.created.toDate()),today,true).months); z < 6; z++) domains[z]++;

            let item = users.get(data.owner)
            if (item) item.domains++;

            promises.push(getDocs(query(collection(db,'domains',domain.id,'assets'))))
        })

        return Promise.all(promises)
    })
    .then(results => {

        for (let i=0; i < results.length; i++){
            const snapshot = results[i];

            snapshot.forEach(game => {
                const data = game.data();

                const created = moment(data.created.toDate())
                const diff1 = moment.preciseDiff(created,today,true)

                const idx = 5-diff1.months;

                for (let z=Math.max(0,idx); z < 6; z++)
                    assets[z]++;

                let item = users.get(data.creator)
                    if (item) item.assets++;
            })
        }

        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
       };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)

        const domainsdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Domains',
                    data: domains,
                },
            ]
        };
        const domainsconfig = {
            type: 'bar',
            data: domainsdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Domains'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let domainsChart = new Chart(document.getElementById('admin-domains-development'),domainsconfig)

        const assetsdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: 'rgba(105, 202, 60, 0.2)',
                    borderColor: 'rgb(105, 202, 60)',
                    borderWidth: 1,
                    label: 'Assets',
                    data: assets,
                },
            ]
        };
        const assetsconfig = {
            type: 'bar',
            data: assetsdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Assets'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let assetsChart = new Chart(document.getElementById('admin-assets-development'),assetsconfig)


        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));

        
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        geo.forEach(function(value, country) {
            geochartdata.addRows([[country, value]]);
        })

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);

        users[Symbol.iterator] = function* () {
            yield* [...this.entries()].sort((a, b) => b[1].lastlogin - a[1].lastlogin);
        }


        $('#admin-user-container').append('<div id="users-input-group" class="input-group mb-3"></div>');{
            $('#users-input-group').append('<span class="input-group-text" id="basic-addon1">@</span>')
            $('#users-input-group').append('<input id="newsletter-url" type="url" class="form-control" placeholder="Newsletter url" aria-label="Newsletter" aria-describedby="Newletter Url">')
            $('#users-input-group').append('<button class="btn btn-outline-secondary" type="button" id="test-button">Test...</button>')
            $('#users-input-group').append('<button class="btn btn-outline-secondary" type="button" id="send-button">Send...</button>')

            tippy('#basic-addon1', {
                content: 'This is mainly for decoration...',
            });
            tippy('#newsletter-url', {
                content: 'Paste the url to the news letter in here...',
            });
            tippy('#test-button', {
                content: 'This will send the newsletter to henrik@raketten.net and +886 909 261 952',
            });
            tippy('#send-button', {
                content: 'This will send the newsletter to the entire list below, unless they already unsubscribed',
            });

            $('#test-button').on('click',function(){
                const url = $('#newsletter-url').val();
                if (url != ""){

                    const basic = NEWSLETTER.replaceAll('{1}', url);

                    const userRef = collection(db,'users')
                    const supportQuery = query(userRef,where('isSupport','==',true))
                
                    getDocs(supportQuery)
                    .then(snapshot => {
                        let promises = [];
                        snapshot.forEach((supportuser) => {
                            const data = supportuser.data();
                            const unsubscribe = 'https://bowling.raketten.net?unsubscribe=1';
                            const simple = 'Hi There,\n\nThanks for using HR Bowling. We have just published a newsletter, please read it online, by clicking this link \n\n' + url + '\n\nThanks Henrik Rasmussen, HR Bowling\n\nUnsubscribe to these messages by clicking this link \n\n' + unsubscribe;

                            if ((data.email != null) && (data.email != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'mail'),{
                                    to: data.email,
                                    message:{
                                        subject: 'Newsletter from HR Bowling',
                                        html: basic.replaceAll('{2}', unsubscribe),
                                        text: simple,
                                    },
                                    created: serverTimestamp()
                                }))
                            }
                            if ((data.phone != null) && (data.phone != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'messages'),{
                                    to: data.phone,
                                    body: simple,
                                    created: serverTimestamp()
                                }))
                            }
                        });
                    })
                    .catch(error => {
                        console.error('Testing newsletter: ' + error)
                    })
                
                }
            })

            $('#send-button').on('click',function(){
                const url = $('#newsletter-url').val();
                if (url != ""){

                    const basic = NEWSLETTER.replaceAll('{1}', url);
                    const unsubscribe = 'https://bowling.raketten.net?unsubscribe=1';
                    const simple = 'Hi There,\n\nThanks for using HR Bowling. We have just published a newsletter, please read it online, by clicking this link\n\n' + url + '\n\nThanks Henrik Rasmussen, HR Bowling\n\nUnsubscribe to these messages by clicking this link \n\n' + unsubscribe;

                    const userRef = collection(db,'users')
                    const userQuery = query(userRef,where('apps', 'array-contains','1:692996812647:web:105441cc44cee29b7489c2'))

                
                    var emails = [];

                    getDocs(userQuery)
                    .then(snapshot => {
                        let promises = [];
                        snapshot.forEach((supportuser) => {
                            const data = supportuser.data();

                            if ((data.email != null) && (data.email != "") && (data.unsubscribed != true)){
                                if (!emails.includes(data.email)) emails.push(data.email);
                            }
                            if ((data.phone != null) && (data.phone != "") && (data.unsubscribed != true)){
                                promises.push(addDoc(collection(db,'messages'),{
                                    to: data.phone,
                                    body: simple,
                                    created: serverTimestamp(),
                                    newsletter: url,
                                }))
                            }
                        });

                        if (emails.length) sendemails(emails,'Newsletter from HR Bowling',basic.replaceAll('{2}', unsubscribe),simple,url)
                    })
                    .catch(error => {
                        console.error('Sending newsletter: ' + error)
                    })
                
                }
            })

        }

        $('#admin-user-container').append('<table id="user-table" class="table align-middle admin-table"></table>')
        $('#user-table').append('<thead><th scope="col">Days</th><th scope="col">Name</th><th scope="col">Communication</th><th scope="col">Country</th><th scope="col">Visits</th><th scope="col">Domains</th><th scope="col">Assets</th></thead>')
        $('#user-table').append('<tbody id="user-table-body"></tbody>')
        for (let [key, value] of users) {


            var maillinktext = "mailto:?to=" + value.email + "&subject=Message from HR Bowling&body=" + getName(value)
            let comm = "";
            if (value.newtokens && value.newtokens['1:692996812647:web:105441cc44cee29b7489c2'] != null) comm += '<a href="#" id="notify' + key + '" style="cursor:pointer"><iconify-icon icon="iconamoon:notification-fill"></iconify-icon></a>'

            if (value.email != null) comm += '<a href="#" id="mail' + key + '" style="cursor:pointer"><iconify-icon icon="ic:baseline-email"></iconify-icon></a>'
            if (value.phone != null) comm += '<a href"#" id="sms' + key + '" style="cursor:pointer"><iconify-icon icon="ic:twotone-smartphone"></iconify-icon></a>'
            else if (value.phoneNumber != null) comm += '<a href"#" id="sms' + key + '" style="cursor:pointer"><iconify-icon icon="ic:twotone-smartphone"></iconify-icon></a>'

            let country = value.country;
            if (value.location && value.location.country) country = value.location.country

            const days = today.diff(moment(value.lastlogin.toDate()), 'days');
            $('#user-table-body').append('<tr><td>' + value.days + '</th><td>' +  getName(value) + '</td><td>' + comm + '</td><td title="' + country + '">' + getFlag(country) + '</td><td>' + value.visits + '</td><td>' + value.domains + '</td><td>' + value.assets + '</td></tr>');

            $('#sms' + key).on('click', function(){
                let number = null;
                if (value.phone != null) number = value.phone;
                else if (value.phoneNumber != null) number = value.phoneNumber
                if (number == null) return;
                newSMS(number)
            })
            $('#mail' + key).on('click', function(){
                let number = null;
                if (value.email != null) number = value.email;
                if (number == null) return;
                newMAIL(number) 
            })
            $('#notify' + key).on('click', function(){
                newNOTIFY(key, '1:692996812647:web:d9e724de772a9c6f7489c2','https://care.raketten.net', 'https://care.raketten.net/notification.png')
            })
        }


        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
        })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })
}
function startQuizTrip(){
    $('#app-page').empty();
    $('#app-spinner').removeClass('hidden')

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })

    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }


    const today = moment() 

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, where('apps', 'array-contains','1:692996812647:web:c66f7aa6157883667489c2'), orderBy('created','asc'))

    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];

    let geo = new Map();

    getDocs(usersQuery)
    .then(snapshot => {



        snapshot.forEach(user => {
            const data = user.data();

            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let cnt = geo.get(data.location.country) || 0;
                cnt++;
                geo.set(data.location.country,cnt);
            }
            else if (data.country){
                let cnt = geo.get(data.country) || 0;
                cnt++;
                geo.set(data.country,cnt);

            }

        })

        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)


        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));

        
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        geo.forEach(function(value, country) {
            geochartdata.addRows([[country, value]]);
        })

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);


        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
        })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })

}
function startPuzzle(){
    $('#app-page').empty();
    $('#app-spinner').removeClass('hidden')

    $('#app-page').append('<nav id="admin-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-top raketten-navbar"></nav>');{
        $('#admin-navbar').append('<div id="admin-navbar-content" class="navbar-content"></div>');{
            $('#admin-navbar-content').append('<div id="graphs-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:graph-line"></iconify-icon></div>')
            $('#admin-navbar-content').append('<div id="map-icon" class="raketten-icon admin-icon"><iconify-icon icon="mdi:earth"></iconify-icon></div>')
        }

        $('.admin-icon').on('click', function(evt){
            $('.admin-container').addClass('hidden')
            $('.admin-icon').removeClass('raketten-icon-selected')
            $(evt.currentTarget).addClass('raketten-icon-selected')
        })
        $('#graphs-icon').on('click', function(){
            $('#admin-graphs-container').removeClass('hidden')
        })
        $('#map-icon').on('click', function(){
            $('#admin-map-container').removeClass('hidden')
        })

    }
    $('#app-page').append('<div id="admin-graphs-container" class="admin-container hidden"></div>');{
        $('#admin-graphs-container').append('<canvas id="admin-users-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-login-development" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-puzzles-created" class="admin-chart"></canvas>')
        $('#admin-graphs-container').append('<canvas id="admin-puzzles-done" class="admin-chart"></canvas>')
    }
    $('#app-page').append('<div id="admin-map-container" class="admin-container hidden"></div>');{
        $('#admin-map-container').append('<div id="mapchart"></div>')
    }


    const today = moment() 

    const usersRef = collection(db,'users');
    const usersQuery = query(usersRef, where('apps', 'array-contains','1:692996812647:web:bf7f1eb6b8f4b4057489c2'), orderBy('created','asc'))

    let creation = [0,0,0,0,0,0];
    let login = [0,0,0,0,0,0];

    let geo = new Map();

    getDocs(usersQuery)
    .then(snapshot => {

        let promises = []

        snapshot.forEach(user => {
            const data = user.data();

            const created = moment(data.created.toDate())
            const lastlogin = moment(data.lastlogin.toDate())

            const diff1 = moment.preciseDiff(created,today,true)
            const diff2 = moment.preciseDiff(lastlogin,today,true)

            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) creation[i]++;
            login[(5-Math.min(diff2.months,5))]++;

            if (data.location && data.location.country){
                let cnt = geo.get(data.location.country) || 0;
                cnt++;
                geo.set(data.location.country,cnt);
            }
            else if (data.country){
                let cnt = geo.get(data.country) || 0;
                cnt++;
                geo.set(data.country,cnt);

            }

        })

        const logindata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Login',
                    data: login,
                },
            ]
        };
        const loginconfig = {
            type: 'bar',
            data: logindata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Users lastlogin'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let loginChart = new Chart(document.getElementById('admin-login-development'),loginconfig)

        const usersdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Users',
                    data: creation,
                },
            ]
        };
        const usersconfig = {
            type: 'bar',
            data: usersdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Users'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let usersChart = new Chart(document.getElementById('admin-users-development'),usersconfig)


        var mapchart = new GoogleCharts.api.visualization.GeoChart(document.getElementById('mapchart'));
        var geochartdata = new GoogleCharts.api.visualization.DataTable();
        geochartdata.addColumn('string', 'Country');
        geochartdata.addColumn('number', 'count');

        geo.forEach(function(value, country) {
            geochartdata.addRows([[country, value]]);
        })

        delete geooptions.region;
        delete geooptions.displayMode;
        mapchart.draw(geochartdata, geooptions);

        const puzzlesRef = collection(db, 'puzzles')
        const puzzlesQuery = query(puzzlesRef, orderBy('created','asc'))
        return getDocs(puzzlesQuery)
    })
    .then(snapshot => {

        let puzzlescreated = [0,0,0,0,0,0];
        let puzzlesdone = [0,0,0,0,0,0];

        snapshot.forEach(puzzle => {
            const data = puzzle.data()
            console.log(data)

            const created = moment(data.created.toDate())
            const diff1 = moment.preciseDiff(created,today,true)
            for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) puzzlescreated[i]++;

            if (data.finished){
                for (let j= 0; j < data.finished.length; j++){
                    const played = moment(data.finished[j].toDate())
                    const diff1 = moment.preciseDiff(played,today,true)
                    for (let i=5; i >= (5-Math.min(diff1.months,5)); i--) puzzlesdone[i]++;
                }
            }
        })

        console.log(puzzlescreated)
        const puzzlesdata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Puzzles Available',
                    data: puzzlescreated,
                },
            ]
        };
        const puzzlesconfig = {
            type: 'bar',
            data: puzzlesdata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Puzzles'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let puzzlesChart = new Chart(document.getElementById('admin-puzzles-created'),puzzlesconfig)


        console.log(puzzlesdone)
        const puzzlesdonedata = {
            labels: ['5','4','3','2','1','0'],
            datasets: [
                {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-25'),
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--light-rgba-100'),
                    borderWidth: 1,
                    label: 'Puzzles Finished',
                    data: puzzlesdone,
                },
            ]
        };
        const puzzlesdoneconfig = {
            type: 'bar',
            data: puzzlesdonedata,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Number of Puzzles'
                    },
                    legend: {
                        display: false,
                    }
                }
            }
        };
        let puzzlesdoneChart = new Chart(document.getElementById('admin-puzzles-done'),puzzlesdoneconfig)

        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')

    })
    .catch(error => {
        console.error('Getting all users: ' + error)
        Message(error)
        $('#graphs-icon').trigger('click')
        $('#app-spinner').addClass('hidden')
    })

}


function getName(data){
    if (data.displayname) return data.displayname;
    if (data.email) return data.email;
    if (data.phone) return data.phone;
    if (data.phoneNumber) return data.phoneNumber;
    return "Stranger"

}

