import { getLanguage } from "../international/international";
import { Message } from "../Message/message";
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';

var theCallback;
var videoElement;
var cameraPhoto = null;
var cameraDevice = FACING_MODES.USER;

import './dialogs.css';

import stringDB from './dialogs.json';
function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}


function createdialog(){
    $('body').append('<div id="camera-spinner" class="spinner-border hidden" role="status"><span class="visually-hidden">Loading...</span></div>')
    $('body').append('<div class="modal" tabindex="-1" id="camera" data-bs-keyboard="false" data-bs-backdrop="static"></div>');{
        $('#camera').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="cameratop"></div>');
        $('#cameratop').append('<div class="modal-content dialogs-content" id="cameracontent"></div>')

        $('#cameracontent').append('<div class="modal-header centered-header dialogs-header" id="cameraheader"></div>')
        $('#cameracontent').append('<div class="modal-body dialogs-body" id="camerabody"></div>')
        $('#cameracontent').append('<div class="modal-footer dialogs-footer" id="camerafooter"></div>')

        $('#cameraheader').append('<div class="modal-title dialogs-title" id="cameratitle"><span class="mdi mdi-camera-outline dialogs-icon"></span>' + zzString(2) + '</div>')
        $('#cameraheader').append('<div class="dialogs-closeicon" id="cameraclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#camerabody').append('<video id="camera-video" autoplay playsinline muted></video>');
        $('#camerabody').append('<img id="camera-photo">');
        $('#camerabody').append('<canvas style="display:none;" id="camera-canvas"></canvas>');

        $('#camerabody').append('<input type="file" id="cameraFileElem" accept="image/*" style="display:none" />');//multiple

        $('#camerafooter').append('<button type="button" id="cameraCancelbutton" class="btn dialogs-footerbutton">' + zzString(0) + '</button>')
        $('#camerafooter').append('<button type="button" id="cameraSwitchbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost"><span class="mdi mdi-cached"></span></button>')
        $('#camerafooter').append('<button type="button" id="cameraGallerybutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost"><span class="mdi  mdi-image-outline"></span></button>')
        $('#camerafooter').append('<button type="button" id="cameraOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">' + zzString(1) + '</button>')

        $('#cameraclosebutton').on('click', function(event) {
            $('#camera').modal('hide');
            cameraPhoto.stopCamera()
            .catch((error)=>{
                console.error('Stopping camera: ' + error)
            });
            if (theCallback) theCallback('')
        });
        $('#cameraSwitchbutton').on('click', function(event) {
            if (cameraDevice == FACING_MODES.USER) cameraDevice = FACING_MODES.ENVIRONMENT;
            else cameraDevice = FACING_MODES.USER

            if (cameraDevice == FACING_MODES.USER){
                cameraPhoto.startCamera(cameraDevice,{})
                .catch((error)=>{
                    console.error('Switching camera: ' + error)
                });
            }else{
                cameraPhoto.startCameraMaxResolution(cameraDevice)
                .catch((error)=>{
                    console.error('Switching camera: ' + error)
                });
        
            }

        });
        $('#cameraOKbutton').on('click', function(event) {
            if ($('#camera-video').hasClass('hidden')){
                cameraPhoto.stopCamera()
                .catch((error)=>{
                    console.error('Stopping camera: ' + error)
                });
        
                const hidden_canvas = document.getElementById('camera-canvas')
                console.log(hidden_canvas)
                const imageDataURL = hidden_canvas.toDataURL('image/png');
                
                if (theCallback) theCallback(imageDataURL);    
                $('#camera').modal('hide');
            }else{

                const hidden_canvas = document.getElementById('camera-canvas')
                const video = document.getElementById('camera-video')
                const image = document.getElementById('camera-photo')
                const width = video.videoWidth
                const height = video.videoHeight
                const context = hidden_canvas.getContext('2d');

                // Set the canvas to the same dimensions as the video.
                hidden_canvas.width = width;
                hidden_canvas.height = height;

                // Draw a copy of the current frame from the video on the canvas.
                context.drawImage(video, 0, 0, width, height);

                // Get an image dataURL from the canvas.
                const imageDataURL = hidden_canvas.toDataURL('image/jpeg');

                // Set the dataURL as source of an image element, showing the captured photo.
                image.setAttribute('src', imageDataURL); 

                $('#camera-video').addClass('hidden')
                $('#camera-photo').removeClass('hidden')
                $('#cameraOKbutton').html(zzString(3))

            }


        });
        $('#cameraCancelbutton').on('click', function(event) {
            if ($('#camera-video').hasClass('hidden')){
                $('#camera-video').removeClass('hidden')
                $('#camera-photo').addClass('hidden')
                $('#cameraOKbutton').html(zzString(4))
            }else{
                $('#cameraclosebutton').trigger('click')
            }
        });

        $('#cameraGallerybutton').on('click', function(){
            $('#cameraFileElem').trigger('click')
        })
        $('#cameraFileElem').on('change', function(e){
            const fileList = this.files
            const numFiles = fileList.length;
            console.log(fileList)

            if (numFiles == 1){
                const fileObj = fileList[0]
                const objectURL = window.URL.createObjectURL(fileObj);

                const hidden_canvas = document.getElementById('camera-canvas')
                const image = document.getElementById('camera-photo')
                const context = hidden_canvas.getContext('2d');

                image.onload = function() {
                    console.log(image)
                    hidden_canvas.width = image.width;
                    hidden_canvas.height = image.height;
        
                    context.drawImage(image, 0,0,image.width,image.height);
                }
                image.setAttribute('src', objectURL); 

                $('#camera-video').addClass('hidden')
                $('#camera-photo').removeClass('hidden')
                $('#cameraOKbutton').html(zzString(3))

            }
        })

        $('#camera').on('shown.bs.modal', function(){
            $('#camera-spinner').addClass('hidden')
        })
        $('#camera').on('hidden.bs.modal', async function(){
            $('#camera-spinner').remove();
            $('#camera').remove();
        })

    }
}

export function camera(callback){
    createdialog()
    videoElement = document.getElementById('camera-video');
    cameraPhoto = new CameraPhoto(videoElement);

    $('#camera-spinner').removeClass('hidden')
    $('#camera-video').removeClass('hidden')
    $('#camera-photo').addClass('hidden')
    $('#cameraOKbutton').html(zzString(4))

    cameraDevice = FACING_MODES.USER;
    cameraPhoto.startCamera(cameraDevice,{})
    .then(()=>{
        theCallback = callback;
        $('#camera').modal('show');    
    })
    .catch((error)=>{
        $('#camera-spinner').addClass('hidden')
        console.error('Starting camera dialog: ' + error)
        Message(error.message, 'error')
        $('#cameraclosebutton').trigger('click')
    });
}

(function() {
})()