import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./components/account/account";
import { Message } from "./components/Message/message";

var theNumber;
var checknumber = null;
var interval = null;

const TESTNUMBER = '+886909261952'

$('body').append('<div class="modal" id="SMS" data-bs-keyboard="false"></div>');{
    $('#SMS').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="SMStop"></div>');
    $('#SMStop').append('<div class="modal-content dialogs-content" id="SMScontent"></div>')

    $('#SMScontent').append('<div class="modal-header centered-header dialogs-header" id="SMSheader"></div>')
    $('#SMScontent').append('<div id="SMSbody" class="modal-body dialogs-body"></div>')
    $('#SMScontent').append('<div class="modal-footer dialogs-footer" id="SMSfooter"></div>')

    $('#SMSheader').append('<svg class="svg-icon27 lightsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H15V9H17M13,11H11V9H13M9,11H7V9H9M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z"/></svg>')
    $('#SMSheader').append('<div class="modal-title">Send SMS</div>')
    $('#SMSheader').append('<div id="SMSclosebutton"><span class="mdi mdi-window-close"></span></div>');

    $('#SMSbody').append('<label for="SMS-text" class="dialogs-label">SMS Text</label>');
    $('#SMSbody').append('<textarea rows="3" id="SMS-text" placeholder="Input message here..."></textarea>')
    $('#SMSbody').append('<div id="SMS-progress"></div>')


    $('#SMSfooter').append('<button type="button" id="SMSTestbutton" class="btn dialogs-footerbutton">Test</button>')
    $('#SMSfooter').append('<button type="button" id="SMSOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">Send</button>')


    $('#SMSclosebutton').click(function(event) {
        if (interval) clearInterval(interval)
        interval = null;
        $('#SMS').modal('hide');
    });
    $('#SMSOKbutton').on('click', function(event) {

        const ref = collection(db,'messages')
        addDoc(ref,{
            to: theNumber,
            body: $('#SMS-text').val(),
            created: serverTimestamp()
        })
        .then(docRef => {
            checknumber = docRef.id;
            $('#SMS-progress').html('SMS to ' + theNumber + ' saved to database')
        })
        .catch(error => {
            Message(error)
        })

        
    });
    $('#SMSTestbutton').on('click', function(event) {

        const ref = collection(db,'messages')
        addDoc(ref,{
            to: TESTNUMBER,
            body: $('#SMS-text').val(),
            created: serverTimestamp()
        })
        .then(docRef => {
            checknumber = docRef.id;
            $('#SMS-progress').html('Test SMS to ' + TESTNUMBER + ' saved to database')
        })
        .catch(error => {
            Message('Could not seve to database<br>' + error)
        })
    });
}

function check(){
    if (!checknumber) return;

    
    const checkRef = doc(db, 'messages', checknumber)
    getDoc(checkRef)
    .then(snapshot => {
        if (!snapshot.exists()) throw('Checknumber doe not exist: ' + checknumber)
        const data = snapshot.data();
        if (data.delivery){
            if (data.delivery.state == "SUCCESS"){
                $('#SMS-progress').html('SMS to ' + data.to + ' was send with status ' + data.delivery.state)
                checknumber = null;
            }
            else if (data.delivery.state == "ERROR"){
                $('#SMS-progress').html('SMS to ' + data.to + ' was send with status ' + data.delivery.state + '<br><br>' + data.delivery.errorMessage)
                checknumber = null;
            }else{
                $('#SMS-progress').html('SMS to ' + data.to + ' has status status ' + data.delivery.state)
            }
        }
    })
    .catch(error => {
        console.error('Checking for SMS delivery: ' + error)
    })
}
export function newSMS(number){
    theNumber = number;
    checknumber = null;
    interval = setInterval(check,5000);
    $('#SMS-progress').html('Ready to send SMS')
    $('#SMS').modal('show')
}