console.log('account.js loaded')

import '@fortawesome/fontawesome-free/css/all.css'
import 'intl-tel-input/build/css/intlTelInput.css'

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { getAuth, RecaptchaVerifier , OAuthProvider, signInWithPopup, sendPasswordResetEmail, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword,signInWithPhoneNumber, fetchSignInMethodsForEmail, linkWithCredential, getAdditionalUserInfo, signInWithCredential, linkWithPhoneNumber, PhoneAuthProvider   } from "firebase/auth";
import { getFirestore,doc,arrayUnion,setDoc, initializeFirestore, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager} from "firebase/firestore"; 
import { getLanguage} from '../international/international';
import intlTelInput from 'intl-tel-input';
import { getMessaging } from "firebase/messaging";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light.css';
tippy.setDefaultProps({
    delay: 2000,
    arrow: true,
    animation: 'scale',
    touch: 'hold',
    allowHTML: true,
    theme: 'light',
    placement: 'auto-start',
});


import stringDB from './account.json';
import './account.css';
import { Message } from "../Message/message";

const firebaseConfig = {
    apiKey: process.env.FIREBASEAPIKEY,
    authDomain: process.env.FIREBASEAUTHDOMAIN,
    projectId: process.env.FIREBASEPROJECTID,
    storageBucket: process.env.FIREBASESTORAGEBUCKET,
    messagingSenderId: process.env.FIREBASEMESSAGESENDERIF,
    appId: process.env.FIREBASEAPPID
};


export const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.RECAPCHAKEY),
      isTokenAutoRefreshEnabled: true
  });
//console.log('APPCHECK', appCheck)

export const auth = getAuth(app);



//export const db = getFirestore(app);




//console.log('initializeFirestore')
export const db = initializeFirestore(app, {
    localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})
  });

export const messaging = getMessaging(app);

var recaptchaSolved;
var recaptchaVerifier;
var confirmationResult;
var phoneInput;


function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}


function getCredential(error){
    //console.log(error.customData)
    switch (error.customData._tokenResponse.providerId){
        case "twitter.com":
            return TwitterAuthProvider.credentialFromError(error);
        case "google.com":
            return GoogleAuthProvider.credentialFromError(error);
        case "microsoft.com":
        case "apple.com":
            return OAuthProvider.credentialFromError(error);
        case "facebook.com":
            return FacebookAuthProvider.credentialFromError(error);
    }
    return null;
}

//Account Dialog for signup/signin
function createAcccountDialog(includeGuest = true){
    $('body').append('<div class="modal" id="account"></div>');{
        $('#account').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="accounttop"></div>');
        $('#accounttop').append('<div class="modal-content dialogs-content" id="accountcontent"></div>')

        $('#accountcontent').append('<div class="modal-header centered-header dialogs-header" id="accountheader"></div>')
        $('#accountcontent').append('<div class="modal-body" id="accountbody"></div>')

        $('#accountheader').append('<div class="modal-title  dialogs-title" id="account-title"></div>')
        $('#accountheader').append('<div id="accountclosebutton"><i class="fa fa-times"></i></div>');

        $('#accountbody').append('<button id="googlebutton" type="button" class="btn tiptool"><div id="googletitleblock"><i class="fab fa-google"></i>' + zzString(0) + '</div></button>')
        tippy('#googlebutton', {
            content: zzString(34),
        });

        $('#accountbody').append('<button id="phonebutton" type="button" class="btn tiptool"><div id="phonetitleblock"><i class="fas fa-phone-alt"></i>' + zzString(1) + '</div></button>')
        tippy('#phonebutton', {
            content: zzString(35),
        });    

        $('#accountbody').append('<button id="facebookbutton" type="button" class="btn tiptool"><div id="facebooktitleblock"><i class="fab fa-facebook-square"></i>' + zzString(2) + '</div></button>')
        tippy('#facebookbutton', {
            content: zzString(36),
        });

        /*$('#accountbody').append('<button id="twitterbutton" type="button" class="btn tiptool"><div id="twittertitleblock"><i class="fab fa-twitter"></i>' + zzString(3) + '</div></button>')
        tippy('#twitterbutton', {
            content: zzString(37),
        });*/

        $('#accountbody').append('<button id="microsoftbutton" type="button" class="btn tiptool"><div id="microsofttitleblock"><i class="fab fa-microsoft"></i>' + zzString(4) + '</div></button>')
        tippy('#microsoftbutton', {
            content: zzString(38),
        });
        
        //$('#accountbody').append('<button id="applebutton" type="button" class="btn"><div id="appletitleblock"><i class="fab fa-apple"></i>' + zzString(5) + '</div></button>')
        $('#accountbody').append('<button id="mailbutton" type="button" class="btn tiptool"><div id="mailtitleblock"><i class="fas fa-envelope"></i>' + zzString(6) + '</div></button>')
        tippy('#mailbutton', {
            content: zzString(39),
        });

        if (process.env.FIREBASEAPPID == '1:692996812647:web:105441cc44cee29b7489c2'){
            if (includeGuest){
                $('#accountbody').append('<button id="guestbutton" type="button" class="btn tiptool"><div id="guesttitleblock"><i class="fas fa-user"></i>' + zzString(7) + '</div></button>')
                tippy('#guestbutton', {
                    content: zzString(40),
                });    
            }
        }

        $('#accountclosebutton').click(function(event) {
            $('#account').modal('hide');
        });
        $('#accountCancelbutton').on('click', function(event) {
            $('#account').modal('hide');
        });
        $('#accountclosebutton').on("keyup", function (ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                ev.stopPropagation();
                $('#account').modal('hide');
            }
        });
        $('#accountOKbutton').on('click', function(event) {
            $('#account').modal('hide');
        });
        $('#account').on('shown.bs.modal', function(){
            let mode = $('#account').prop('mode');
            if (mode == 'signup'){
                $('#account-title').html('<span class="mdi mdi-login"></span>' + zzString(8));
            }else if (mode == 'signin'){
                $('#account-title').html('<span class="mdi mdi-login"></span>' + zzString(9));
            }else if (mode == 'link'){
                $('#account-title').html('<span class="mdi mdi-login"></span>' + zzString(10));
            }else{
                console.warning('Invoking account dialog with unknown mode')
            }
        })
        $('#account').on('hidden.bs.modal', function(){
            $('#account').remove();
        })
        $('#account').on("keyup", function (ev) {
            if ((ev.keyCode === 13) && !$('#accountclosebutton').is(":focus")) {
                ev.preventDefault();
                ev.stopPropagation();
                $('#accountOKbutton').trigger('click');
            }
        });
        function login(provider){
            const mode = $('#account').prop('mode');
            auth.languageCode = getLanguage();
            $('#account').modal('hide');

            if (mode == 'link'){
                linkWithPopup(auth.currentUser, provider)
                .then((result) => {
                    Message(zzString(41), 'info')
                }).catch((error) => {
                    const errorMessage = error.message;
                    console.error('Linking with Popup: ' + errorMessage, error)

                    if (error.code === 'auth/account-exists-with-different-credential') {
                        Message(zzString(42));
                    }else{
                        Message(errorMessage)
                    }
                });
            }else{
                signInWithPopup(auth, provider)
                .then((result) => {
                    //console.log('LOGIN OK',provider,result)
                    const details = getAdditionalUserInfo(result)
                }).catch((error) => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                        const email = error.customData.email;
                        const credential = getCredential(error);
    
                        confirmMerge(email,credential);               
                    }else{
                        console.error('Login error', error.message)
                        Message( error.message,"error")
                    }
                });    
            }
        }

        

        $('#mailbutton').click(function(){
            //console.log('mailbutton', $('#account').prop('mode'))
            DoMailPassword($('#account').prop('mode'),"",null)
            $('#account').modal('hide');
        })
        $('#guestbutton').click(function(){
            $('#account').modal('hide');
            signInAnonymously(auth)
            .then(() => {
                //console.log('tell user')
                //Message(zzString(10),'warning')
            })
            .catch((error) => {
                const errorMessage = error.message;
                Message(errorMessage,'error')
            });
        })
        $('#microsoftbutton').click(function(){
            let provider = new OAuthProvider('microsoft.com');
            login(provider);
        })
        $('#applebutton').click(function(){
            let provider = new OAuthProvider('apple.com');
            login(provider);
        })
        $('#googlebutton').click(function(){
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            login(provider);
        })
        $('#phonebutton').click(function(){
            createPhoneDialog();
            $('#phonedialog').prop('mode',$('#account').prop('mode'))
            $('#account').modal('hide');
            $('#phonedialog').modal('show')
        })
        $('#twitterbutton').click(function(){
            login(new TwitterAuthProvider());
        })
        $('#facebookbutton').click(function(){
            const provider = new FacebookAuthProvider();
            provider.addScope('public_profile');
            provider.addScope('email');
            provider.setCustomParameters({
                'display': 'popup'
            });
            login(provider);
        })
    }
}

var linkcredential;
function createMailPasswordDialog(){
    $('body').append('<div class="modal" id="mailpassword"></div>');{
        $('#mailpassword').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="mailpasswordtop"></div>');
        $('#mailpasswordtop').append('<div class="modal-content dialogs-content " id="mailpasswordcontent"></div>')

        $('#mailpasswordcontent').append('<div class="modal-header centered-header dialogs-header" id="mailpasswordheader"></div>')
        $('#mailpasswordcontent').append('<form id="mailpasswordbody" class="modal-body dialogs-body row g-2 mailpassword-page needs-validation" novalidate></form>')
        $('#mailpasswordcontent').append('<div class="modal-footer dialogs-footer" id="mailpasswordfooter"></div>')

        $('#mailpasswordheader').append('<div id="mailpasswordtitle" class="modal-title dialogs-title"><span class="mdi mdi-shield-account-outline dialogs-icon"></span>' + zzString(11) + '</div>')
        $('#mailpasswordheader').append('<div id="mailpasswordclosebutton" tabindex="-1"><i class="fa fa-times" ></i></div>');

        $('#mailpasswordbody').append('<div id="mailpassword-row1" class="row g-2"></row>');{
            $('#mailpassword-row1').append('<div id= "mailpassword-row1-col1" class="form-floating col-md-12"></div>')
                $('#mailpassword-row1-col1').append('<input type="email" class="form-control" id="mailpassword-email" required tabindex="1">')
                $('#mailpassword-row1-col1').append('<label for="mailpassword-email">' + zzString(12) + '</label>');
                $('#mailpassword-row1-col1').append('<div class="invalid-feedback">' + zzString(13) + '</div>')
        }

        $('#mailpasswordbody').append('<div id="mailpassword-row2" class="row g-2"></row>');{
            $('#mailpassword-row2').append('<div id= "mailpassword-row2-col1" class="form-floating col-md-12"></div>')
                $('#mailpassword-row2-col1').append('<input type="password"  minlength="6" required class="form-control" id="mailpassword-password" tabindex="2">')
                $('#mailpassword-row2-col1').append('<label for="mailpassword-homepage">' + zzString(14) + '</label>');
                $('#mailpassword-row2-col1').append('<div class="invalid-feedback">' + zzString(15) + '</div>')
        }

        $('#mailpasswordbody').append('<button type="button" id="mailpassword-forgotpassword" class="hidden" tabindex="-1">' + zzString(16) + '</button>');

        $('#mailpasswordfooter').append('<button type="button" id="mailpasswordCancelbutton" class="btn dialogs-footerbutton" tabindex="4">' + zzString(17) + '</button>')
        $('#mailpasswordfooter').append('<button type="button" id="mailpasswordOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost" tabindex="3">' + zzString(18) + '</button>')

        $('#mailpasswordclosebutton').click(function(event) {
            $('#mailpassword').modal('hide');
        });
        $('#mailpasswordCancelbutton').on('click', function(event) {
            $('#mailpassword').modal('hide');
        });

        $('#mailpassword-forgotpassword').click(evt => {
            let email = $('#mailpassword-email').val();
            //console.log(email)
            if (document.getElementById('mailpassword-email').checkValidity() && (email != "")){
                sendPasswordResetEmail(auth, email)
                .then(() => {
                    Message(zzString(19))
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    Message(zzString(20) + ' (' + errorMessage + ')')
                });
                
                
            }else{
                //console.log('email not good')
                Message(zzString(21), 'warning')
            }

            evt.preventDefault();
            evt.stopPropagation();

        })

        

        $('#mailpasswordOKbutton').on('click', function(event) {
            if (!document.getElementById('mailpasswordbody').checkValidity() ){
                event.preventDefault()
                event.stopPropagation()
                $('#mailpasswordbody').addClass('was-validated')
                return;
            }

            const mode = $('#mailpassword').prop('mode')
            const email = $('#mailpassword-email').val()
            const password = $('#mailpassword-password').val()

            $('#mailpassword').modal('hide');

            

            if (mode == 'signup'){
                createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                })
                .catch((error) => {
                    console.error('sign up failed',error.code)
                    if (error.code === 'auth/email-already-in-use') {
                        signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                        })
                        .catch((error) => {
                            const credential = EmailAuthProvider.credential(email, password);    
                            confirmMerge(email,credential);               
                        });
                    }else{
                        Message(error.message,"error")
                    }
                });
            
            }else if (mode == 'signin'){
                signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    if (linkcredential != null)
                        return linkWithCredential(user,linkcredential);
                })
                .then(function(){
                    //console.log('linked')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    //console.log(error,errorCode,errorMessage)

                    if (errorCode == 'auth/user-not-found') Message(zzString(22),'error')
                    else if (errorCode === 'auth/wrong-password') Message(zzString(23),'error')
                    else Message(zzString(24) + '<br>' + errorMessage,'error')

                    $('#mailpassword').modal('show');
                });
            }else if (mode == 'link'){
                const credential = EmailAuthProvider.credential(email, password);
                linkWithCredential(auth.currentUser,credential)
                .then(() => {
                    Message(zzString(41),'info')
                })
                .catch(error => {
                    const errorMessage = error.message;
                    console.error('Linking with Email: ' + errorMessage, error)

                    if (error.code === 'auth/account-exists-with-different-credential') {
                        Message(zzString(42));
                    }else{
                        Message(errorMessage)
                    }
                })
            }else
                console.warning('Using mailpassword dialog in illegal mode: ' + mode)

        });
        $('#mailpassword').on('shown.bs.modal', async function(){
            $('#mailpasswordbody').removeClass('was-validated')
            $('#mailpassword-email').focus();
            const mode = $('#mailpassword').prop('mode')
            //console.log('show',mode)
            if (mode=='signin') $('#mailpassword-forgotpassword').removeClass('hidden')

        })
        $('#mailpassword').on('hidden.bs.modal', async function(){
            $('#mailpassword').remove();
        })

        $('#mailpassword').on('keypress',function(evt){
            //console.log('keypress')
            if ((evt.keycode == 13 || evt.which == 13)){
                console.log('enter')
                $('#mailpasswordOKbutton').trigger('click')
            }
        })
    }
}
function DoMailPassword(mode,email,credential=null){
    //console.log('DoMailPassword',mode)
    linkcredential = credential;
    createMailPasswordDialog();
    $('#mailpassword').prop('mode',mode);
    
    $('#mailpassword-email').val(email)
    $('#mailpassword-password').val("")

    $('#mailpassword-email').attr('readonly',email != "")
    
    $('#mailpassword').modal('show')
}

function createPhoneDialog(){
    $('body').append('<div class="modal" id="phonedialog" data-bs-keyboard="false"></div>');{
        $('#phonedialog').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="phonedialogtop"></div>');
        $('#phonedialogtop').append('<div class="modal-content dialogs-content" id="phonedialogcontent"></div>')

        $('#phonedialogcontent').append('<div class="modal-header centered-header dialogs-header" id="phonedialogheader"></div>')
        $('#phonedialogcontent').append('<div class="modal-body dialogs-body" id="phonedialogbody"></div>')
        $('#phonedialogcontent').append('<div class="modal-footer dialogs-footer" id="phonedialogfooter"></div>')

        $('#phonedialogheader').append('<div class="modal-title dialogs-title" id="phonedialogtitle"><span class="mdi mdi-phone dialogs-icon"></span>' + zzString(25) + '</div>')
        $('#phonedialogheader').append('<div class="dialogs-closeicon" id="phonedialogclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#phonedialogbody').append('<input type="tel" id="phonedialogphonenumber" />')
        $('#phonedialogbody').append('<div id="illegalphonenumber" class="hidden">' + zzString(27) + '</div>');

        $('#phonedialogbody').append('<div id="recaptha"></div>')
        $('#phonedialogbody').append('<div id="illegalrecaptha" class="hidden">' + zzString(28) + '</div>');

        $('#phonedialogfooter').append('<button type="button" id="phonedialogCancelbutton" class="btn dialogs-footerbutton">' + zzString(17) + '</button>')
        $('#phonedialogfooter').append('<button type="button" id="phonedialogOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">' + zzString(18) + '</button>')

        $('#phonedialogphonenumber').keyup(function(){
            $('#illegalphonenumber').addClass('hidden')
        });
        $('#phonedialogclosebutton').click(function(event) {
            $('#phonedialog').modal('hide');
        });
        $('#phonedialogCancelbutton').on('click', function(event) {
            $('#phonedialog').modal('hide');
        });
        $('#phonedialogOKbutton').on('click', function(event) {
            if (!phoneInput.isValidNumber()){
                $('#illegalphonenumber').removeClass('hidden')
                return;
            }
            if (!recaptchaSolved){
                $('#illegalrecaptha').removeClass('hidden')
                return;
            }

            let phonenumber = phoneInput.getNumber();
            //console.log('phone number',phonenumber)

            const mode = $('#phonedialog').prop('mode');

            //console.log(mode)


            if (mode == 'link'){
                //console.log('linkwithphonenumber')
                linkWithPhoneNumber(auth.currentUser,phonenumber,recaptchaVerifier)
                .then(result => {
                    //console.log('linkWithPhoneNumber ok',result)
                    confirmationResult = result;
                    $('#phonedialog').modal('hide');
                    createConfirmPhoneDialog();
                    $('#confirmphone').prop('mode',mode)
                    $('#confirmphone').modal('show')
                })
                .catch(error => {
                    const errorMessage = error.message;
                    console.error('linkWithPhoneNumber: ' + errorMessage)
                    $('#phonedialog').modal('hide');
                    Message(errorMessage)
                })
            }else{
                signInWithPhoneNumber(auth, phonenumber, recaptchaVerifier)
                .then((result) => {
                    //console.log('ok',result)
                    confirmationResult = result;
                    $('#phonedialog').modal('hide');
                    createConfirmPhoneDialog();
                    $('#confirmphone').prop('mode',mode)
                    $('#confirmphone').modal('show')
                }).catch((error) => {
                    const errorMessage = error.message;
                    console.error('signInWithPhoneNumber: ' + errorMessage)
                    $('#phonedialog').modal('hide');
                    Message(errorMessage)
                });    
            }
        });
        $('#phonedialog').on('shown.bs.modal', async function(){
            $('#illegalphonenumber').addClass('hidden')
            recaptchaSolved = false;
            recaptchaVerifier = new RecaptchaVerifier('recaptha', {
                'size': 'compact',
                'callback': (response) => {
                    //console.log('solved');
                    $('#illegalrecaptha').addClass('hidden')
                    recaptchaSolved = true;
                },
                'expired-callback': () => {
                    console.warning('expired');
                }
            }, auth);
            recaptchaVerifier.render()
        })
        $('#phonedialog').on('hidden.bs.modal', async function(){
            recaptchaVerifier.clear();
            $('#phonedialog').remove();
        })
        
        const phoneInputField = document.querySelector("#phonedialogphonenumber");
        phoneInput = intlTelInput(phoneInputField, {
            autoPlaceholder: "aggressive",
            initialCountry: "auto",
            geoIpLookup: function(success) {
                // Get your api-key at https://ipdata.co/
                fetch("https://api.ipdata.co/?api-key=d72058cf48671487a006307e13c1419dfdfadc24ff3f821658c1b8de")
                .then(function(response) {
                    if (!response.ok) return success("");
                    return response.json();
                })
                .then(function(ipdata) {
                    success(ipdata.country_code);
                })
                .catch(error => {
                    return success(""); 
                })
            },
            separateDialCode: true,
            preferredCountries: ["jp","us", "dk", "se", "tw"],
            utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
    }
}
function createConfirmPhoneDialog(){
    $('body').append('<div class="modal" id="confirmphone"></div>');{
        $('#confirmphone').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="confirmphonetop"></div>');
        $('#confirmphonetop').append('<div class="modal-content dialogs-content" id="confirmphonecontent"></div>')

        $('#confirmphonecontent').append('<div class="modal-header centered-header dialogs-header" id="confirmphoneheader"></div>')
        $('#confirmphonecontent').append('<div class="modal-body dialogs-body" id="confirmphonebody"></div>')
        $('#confirmphonecontent').append('<div class="modal-footer dialogs-footer" id="confirmphonefooter"></div>')

        $('#confirmphoneheader').append('<div class="modal-title dialogs-title" id="confirmphonetitle"><span class="mdi mdi-code-tags dialogs-icon"></span>' + zzString(29) + '</div>')
        $('#confirmphoneheader').append('<div class="dialogs-closeicon" id="confirmphoneclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#confirmphonebody').append('<div id="confirmphonesubtitle"><span class="mdi mdi-code-tags"></span>' + zzString(30) + '</div>');
        $('#confirmphonebody').append('<input type="text" id="confirmphonecode" />')
        $('#confirmphonebody').append('<div id="illegalcode" class="hidden">' + zzString(31) + '</div>');

        $('#confirmphonefooter').append('<button type="button" id="confirmphoneCancelbutton" class="btn dialogs-footerbutton">' + zzString(17) + '</button>')
        $('#confirmphonefooter').append('<button type="button" id="confirmphoneOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">' + zzString(18) + '</button>')

        $('#confirmphonecode').keyup(function(){
            $('#illegalcode').addClass('hidden')
        });

        $('#confirmphoneclosebutton').click(function(event) {
            $('#confirmphone').modal('hide');
            createPhoneDialog();
            $('#phonedialog').modal('show')
        });
        $('#confirmphoneCancelbutton').on('click', function(event) {
            $('#confirmphone').modal('hide');
            createPhoneDialog();
            $('#phonedialog').modal('show')
        });
        $('#confirmphoneOKbutton').on('click', function(event) {

            const mode = $('#confirmphone').prop('mode');
            let code = $('#confirmphonecode').val();
            
            //console.log(mode);

            if (mode == 'link'){
                confirmationResult.confirm(code)
                .then((result) => {
                    const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, code)
                    return linkWithCredential(auth.currentUser, credential)
                })
                .then(() => {
                    $('#confirmphone').modal('hide');
                    Message(zzString(41), 'info')
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    console.error('Confirming link code: ' + errorMessage, error)

                    if (error.code === 'auth/account-exists-with-different-credential') {
                        $('#confirmphone').modal('hide');
                        Message(zzString(42));
                    }else if (error.code == 'auth/invalid-verification-code') {
                        $('#illegalcode').removeClass('hidden')
                    }else{
                        Message(errorMessage)
                    }
                });    

            }else{
                confirmationResult.confirm(code)
                .then((result) => {
                    $('#confirmphone').modal('hide');
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    console.error('Confirming signin code: ' + errorMessage, error)
                    if (error.code == 'auth/invalid-verification-code') {
                        $('#illegalcode').removeClass('hidden')
                    }else{
                        Message(errorMessage)
                    }
                });    
            }

        });
        $('#confirmphone').on('shown.bs.modal', async function(){
            $('#confirmphonecode').val('')
            $('#confirmphonecode').focus()
        })
        $('#confirmphone').on('hidden.bs.modal', async function(){
            $('#confirmphone').remove();
        })
        

    }
}

var theemail;
var thecredential;
function createConfirmMergeDialog(){
    $('body').append('<div class="modal" id="confirmMergeDialog"></div>');{
        $('#confirmMergeDialog').append('<div class="modal-dialog modal-dialog-centered" id="confirmMergeDialogtop"></div>');
        $('#confirmMergeDialogtop').append('<div class="modal-content dialogs-content" id="confirmMergeDialogcontent"></div>')

        $('#confirmMergeDialogcontent').append('<div class="modal-header centered-header dialogs-header" id="confirmMergeDialogheader"></div>')
        $('#confirmMergeDialogcontent').append('<form id="confirmMergeDialogbody" class="modal-body dialogs-body row g-2 confirmMergeDialog-page"></form>')
        $('#confirmMergeDialogcontent').append('<div class="modal-footer dialogs-footer" id="confirmMergeDialogfooter"></div>')

        $('#confirmMergeDialogheader').append('<div class="modal-title dialogs-title" id="confirmMergeDialogtitle"><span class="mdi mdi-check dialogs-icon"></span>' + zzString(32) + '</div>')
        $('#confirmMergeDialogheader').append('<div class="dialogs-closeicon" id="confirmMergeDialogclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#confirmMergeDialogbody').append('<div id="confirmMergeDialog-row2" class="row g-2"></row>');{
            $('#confirmMergeDialog-row2').append('<div id= "confirmMergeDialog-row1-col1" class="form-floating col-md-12">' + zzString(33) +'</div>')
        }

        $('#confirmMergeDialogfooter').append('<button type="button" id="confirmMergeDialogCancelbutton" class="btn dialogs-footerbutton">' + zzString(17) + '</button>')
        $('#confirmMergeDialogfooter').append('<button type="button" id="confirmMergeDialogOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">' + zzString(18) + '</button>')

        $('#confirmMergeDialogclosebutton').click(function(event) {
            $('#confirmMergeDialog').modal('hide');
        });
        $('#confirmMergeDialogCancelbutton').on('click', function(event) {
            $('#confirmMergeDialog').modal('hide');
        });

        function getProviderForProviderId(id){
            //console.log(id)
            switch (id){
                case "twitter.com":
                    return new TwitterAuthProvider();
                case "google.com":
                    return new GoogleAuthProvider();
                case "microsoft.com":
                    return new OAuthProvider('microsoft.com')
                case "apple.com":
                    return new OAuthProvider('apple.com')
                case "facebook.com":
                    return new FacebookAuthProvider();
            }
            return null;
        }

        $('#confirmMergeDialogOKbutton').on('click', function(event) {
            $('#confirmMergeDialog').modal('hide');

            fetchSignInMethodsForEmail(auth, theemail)
            .then(function(methods) {

                if (methods[0] === 'password') {
                    DoMailPassword('signin',theemail,thecredential);
                }else{
                    var provider = getProviderForProviderId(methods[0]);
                    
                    signInWithPopup(auth, provider)
                    .then(function(result) {
                        return linkWithCredential(result.user, thecredential)
                    })
                    .then(function(usercred) {
                        //console.log('linked')
                    })
                }
            })
        })

        $('#confirmMergeDialog').on('hidden.bs.modal', async function(){
            $('#confirmMergeDialog').remove();
        })
    }
}
function confirmMerge(email, credential)
{
    theemail = email;
    thecredential = credential
    createConfirmMergeDialog();
    $('#confirmMergeDialog').modal('show')
}

export function doSignIn(){
    createAcccountDialog();
    $('#account').prop('mode','signin')
    //console.log('doSignIn', $('#account').prop('mode'))
    $('#account').modal('show');
}
export function doSignUp(){
    createAcccountDialog();
    $('#account').prop('mode','signup')
    $('#account').modal('show');    
}
export function doLink(){
    createAcccountDialog(false, false);
    $('#account').prop('mode','link')
    $('#account').modal('show');    
}

export function createUser(){
    signInAnonymously(auth);
}