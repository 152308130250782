import { getLanguage } from "../international/international";

var theCallback;


jscolor.presets.default = {
	width: 201,
	height: 81,
	position: 'top',
	previewPosition: 'right',
	backgroundColor: '#f3f3f3',
	borderColor: '#bbbbbb',
	controlBorderColor: '#bbbbbb',
	palette: [
        //B&W        GRAY1      //GRAY2     //RED       //ORANGE    //YELLOW    //GREEN      //BLUE1    //BLUE2     //PURPLE                  
		'#0a0a0a',  '#D3D3D3',  '#808080',  '#ca3c69',  '#FF5F1F',  '#FFEA00',  '#69ca3c',  '#7393B3',  '#3c69ca',  '#7F00FF',
		'#FAFAFA',  '#A9A9A9',  '#A52A2A',  '#feaec9',  '#FF7F50',  '#FFFF8F',  '#b5e61d',  '#088F8F',  '#0096FF',  '#CF9FFF',
	],
	paletteCols: 10,
	hideOnPaletteClick: true,
}


import stringDB from './settings.json';
function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}

function createdialog(){
    $('body').append('<div class="modal" id="customColor" data-bs-keyboard="false"></div>');{
        $('#customColor').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="customColortop"></div>');
        $('#customColortop').append('<div class="modal-content dialogs-content" id="customColorcontent"></div>')

        $('#customColorcontent').append('<div class="modal-header centered-header dialogs-header" id="customColorheader"></div>')
        $('#customColorcontent').append('<div id="customColorbody" class="modal-body dialogs-body"></div>')
        $('#customColorcontent').append('<div class="modal-footer dialogs-footer" id="customColorfooter"></div>')

        $('#customColorheader').append('<svg class="svg-icon27 lightsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2C17.5,2 22,6 22,11A6,6 0 0,1 16,17H14.2C13.9,17 13.7,17.2 13.7,17.5C13.7,17.6 13.8,17.7 13.8,17.8C14.2,18.3 14.4,18.9 14.4,19.5C14.5,20.9 13.4,22 12,22M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C12.3,20 12.5,19.8 12.5,19.5C12.5,19.3 12.4,19.2 12.4,19.1C12,18.6 11.8,18.1 11.8,17.5C11.8,16.1 12.9,15 14.3,15H16A4,4 0 0,0 20,11C20,7.1 16.4,4 12,4M6.5,10C7.3,10 8,10.7 8,11.5C8,12.3 7.3,13 6.5,13C5.7,13 5,12.3 5,11.5C5,10.7 5.7,10 6.5,10M9.5,6C10.3,6 11,6.7 11,7.5C11,8.3 10.3,9 9.5,9C8.7,9 8,8.3 8,7.5C8,6.7 8.7,6 9.5,6M14.5,6C15.3,6 16,6.7 16,7.5C16,8.3 15.3,9 14.5,9C13.7,9 13,8.3 13,7.5C13,6.7 13.7,6 14.5,6M17.5,10C18.3,10 19,10.7 19,11.5C19,12.3 18.3,13 17.5,13C16.7,13 16,12.3 16,11.5C16,10.7 16.7,10 17.5,10Z"/></svg>')
        $('#customColorheader').append('<div id="customColorTitle" class="modal-title">' + zzString(59) + '</div>')
        $('#customColorheader').append('<div id="customColorclosebutton"><span class="mdi mdi-window-close"></span></div>');

        $('#customColorbody').append('<div id="main-color-setting" class="color-setting"></div>');{
            $('#main-color-setting').append('<div class="color-label">' + zzString(31) + '</div>')
            $('#main-color-setting').append('<input id="main-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#main-color')[0]);
        }

        $('#customColorbody').append('<div id="dark-color-setting" class="color-setting"></div>');{
            $('#dark-color-setting').append('<div class="color-label">' + zzString(32) + '</div>')
            $('#dark-color-setting').append('<input id="dark-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#dark-color')[0]);
        }

        $('#customColorbody').append('<div id="danger-color-setting" class="color-setting"></div>');{
            $('#danger-color-setting').append('<div class="color-label">' + zzString(33) + '</div>')
            $('#danger-color-setting').append('<input id="danger-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#danger-color')[0]);
        }

        $('#customColorbody').append('<div id="white-color-setting" class="color-setting"></div>');{
            $('#white-color-setting').append('<div class="color-label">' + zzString(34) + '</div>')
            $('#white-color-setting').append('<input id="white-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#white-color')[0]);
        }

        $('#customColorbody').append('<div id="lightgray-color-setting" class="color-setting"></div>');{
            $('#lightgray-color-setting').append('<div class="color-label">' + zzString(36) + '</div>')
            $('#lightgray-color-setting').append('<input id="lightgray-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#lightgray-color')[0]);
        }

        $('#customColorbody').append('<div id="gray-color-setting" class="color-setting"></div>');{
            $('#gray-color-setting').append('<div class="color-label">' + zzString(37) + '</div>')
            $('#gray-color-setting').append('<input id="gray-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#gray-color')[0]);
        }

        $('#customColorbody').append('<div id="darkgray-color-setting" class="color-setting"></div>');{
            $('#darkgray-color-setting').append('<div class="color-label">' + zzString(38) + '</div>')
            $('#darkgray-color-setting').append('<input id="darkgray-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#darkgray-color')[0]);
        }

        $('#customColorbody').append('<div id="black-color-setting" class="color-setting"></div>');{
            $('#black-color-setting').append('<div class="color-label">' + zzString(35) + '</div>')
            $('#black-color-setting').append('<input id="black-color" data-jscolor="{}" class="color-setting-input"></input>')
            new jscolor($('#black-color')[0]);
        }


        $('#customColorfooter').append('<button type="button" id="customColorCancelbutton" class="btn dialogs-footerbutton">' + zzString(61) + '</button>')
        $('#customColorfooter').append('<button type="button" id="customColorOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">' + zzString(60) + '</button>')


        $('#customColorclosebutton').click(function(event) {
            $('#customColor').modal('hide');
        });
        $('#customColorOKbutton').on('click', function(event) {
            const colors = {
                lightcolor: $('#main-color').val(),
                darkcolor: $('#dark-color').val(),
                dangercolor:$('#danger-color').val(),
                whitecolor:$('#white-color').val(),
                lightgraycolor:$('#lightgray-color').val(),
                graycolor:$('#gray-color').val(),
                darkgraycolor:$('#darkgray-color').val(),
                blackcolor:$('#black-color').val(),
                theme: 'light'
            }
            console.log(colors);
            if (theCallback) theCallback(colors)

            $('#customColor').modal('hide');
        });
        $('#customColorCancelbutton').on('click', function(event) {
            $('#customColor').modal('hide');
        });

        $('#customColor').on('shown.bs.modal', function(){
            const lightcolor = getComputedStyle(document.documentElement).getPropertyValue('--light-color')
            $('#main-color')[0].jscolor.fromString(lightcolor)    
            const darkcolor = getComputedStyle(document.documentElement).getPropertyValue('--dark-color')
            $('#dark-color')[0].jscolor.fromString(darkcolor)    
            const dangercolor = getComputedStyle(document.documentElement).getPropertyValue('--danger-color')
            $('#danger-color')[0].jscolor.fromString(dangercolor)   
            const whitecolor = getComputedStyle(document.documentElement).getPropertyValue('--white-color')
            $('#white-color')[0].jscolor.fromString(whitecolor)    
            const lightgraycolor = getComputedStyle(document.documentElement).getPropertyValue('--lightgray-color')
            $('#lightgray-color')[0].jscolor.fromString(lightgraycolor)    
            const graycolor = getComputedStyle(document.documentElement).getPropertyValue('--gray-color')
            $('#gray-color')[0].jscolor.fromString(graycolor)    
            const darkgraycolor = getComputedStyle(document.documentElement).getPropertyValue('--darkgray-color')
            $('#darkgray-color')[0].jscolor.fromString(darkgraycolor)    
            const blackcolor = getComputedStyle(document.documentElement).getPropertyValue('--black-color')
            $('#black-color')[0].jscolor.fromString(blackcolor)    
        })
        $('#customColor').on('hidden.bs.modal', async function(){
            $('#customColor').remove();
        })

    }
}

export function customColor(callback){
    theCallback = callback;
    createdialog();
    $('#customColor').modal('show')
}