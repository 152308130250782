import { getFunctions, httpsCallable } from "firebase/functions"

console.log('notification.js loaded')

const functions = getFunctions()
const sendNotification = httpsCallable(functions, 'sendNotification');

export function notifyUser(user,title,text,appid=process.env.FIREBASEAPPID,link=process.env.APPURL,image=process.env.APPURL + "/notification.png"){

    console.log('notifyuser',user,title,text,appid,link,image);

    return new Promise(function(resolve, reject) {

        const data = { 
            image: image, 
            link: link, 
            app: appid,
            user: user, 
            title:title, 
            text: text,
        }

        sendNotification(data)
        .then(() => {
            resolve()
        })
        .catch(error => {
            reject(error)
        })
    })
}
