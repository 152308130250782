import { FieldPath, Timestamp, addDoc, collection, doc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { auth, db } from "../account/account";
import { getDateString, getLanguage, getVeryShortDateString } from "../international/international";
import { Message } from "../Message/message";
import moment from 'moment';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light.css';

tippy.setDefaultProps({
    delay: 2000,
    arrow: true,
    animation: 'scale',
    touch: 'hold',
    allowHTML: true,
    theme: 'light',
    placement: 'auto-start',
});

import stringDB from './settings.json';
function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}

let theCallback = null;

function createdialog(){
    $('body').append('<div class="modal" id="whyLeave" data-bs-keyboard="false"></div>');{
        $('#whyLeave').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="whyLeavetop"></div>');
        $('#whyLeavetop').append('<div class="modal-content dialogs-content" id="whyLeavecontent"></div>')
        $('#whyLeavecontent').append('<div id="whyLeavebody" class="modal-body dialogs-body"></div>')

        $('#whyLeavebody').append('<div>' + zzString(19) + '</div>')

        
        $('#whyLeavebody').append('<h2>' + zzString(126) + '</h2>')
        $('#whyLeavebody').append('<h4>' + zzString(127) + '</h4>')

        $('#whyLeavebody').append('<div id="LeavingReasons"></div>');{
            $('#LeavingReasons').append('<div id="LeavingReason1" class="LeavingReason">' + zzString(128) + '</div>')
            $('#LeavingReasons').append('<div id="LeavingReason2" class="LeavingReason">' + zzString(129) + '</div>')
            $('#LeavingReasons').append('<div id="LeavingReason3" class="LeavingReason">' + zzString(130) + '</div>')
            $('#LeavingReasons').append('<div id="LeavingReason4" class="LeavingReason">' + zzString(131) + '</div>')
        }
        $('#whyLeavebody').append('<textarea rows="3" class="tiptool" id="leaving-comment" placeholder="' + zzString(132) + '"></textarea>')

        $('#whyLeavebody').append('<button type="button" id="whyLeaveOKbutton" class="btn btn-raketten-light btn-raketten-150">' + zzString(60) + '</button>')

        $('.LeavingReason').on('click', function() {
            updateDoc(doc(db, 'users', auth.currentUser.uid),new FieldPath('leaving','reason'), $(this).html()) 
            $('.LeavingReason').removeClass('reasonselected')
            $(this).addClass('reasonselected')
        })
        $('#leaving-comment').on('change',function(){
            updateDoc(doc(db, 'users', auth.currentUser.uid),new FieldPath('leaving','comment'), $(this).val())
        })

        $('#whyLeaveOKbutton').click(function(event) {
            $('#whyLeave').modal('hide');
        });
        $('#whyLeave').on('shown.bs.modal', function(){
        })
        $('#whyLeave').on('hidden.bs.modal', async function(){
            $('#whyLeave').remove();
            theCallback()
        })
    }
}

export function whyLeave(callback){
    theCallback = callback
    createdialog();
    $('#whyLeave').modal('show');
}