import { getLanguage } from "../international/international";

var theCallback;

import './message.css'
import stringDB from './message.json';

function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}

function createdialog(){
    $('body').append('<div class="modal" tabindex="-1" id="telluser" style="z-index:1600;"></div>');{
        $('#telluser').append('<div class="modal-dialog modal-dialog-centered" id="tellusertop"></div>');
        $('#tellusertop').append('<div class="modal-content" id="tellusercontent"></div>')

        $('#tellusercontent').append('<div class="modal-header centered-header" id="telluserheader"></div>')
        $('#tellusercontent').append('<div class="modal-body" id="telluserbody"></div>')
        $('#tellusercontent').append('<div class="modal-footer" id="telluserfooter"></div>')

        $('#telluserheader').append('<div class="modal-title modal-title-admin" id="tellusertitle"></div>')

        $('#telluserbody').append('<div id="tellusermessage"></div>');

        $('#telluserfooter').append('<button tabindex="0" type="button" id="telluserOKbutton" class="btn btn-sm">' + zzString(0) + '</button>')
        $('#telluserfooter').append('<button tabindex="1" type="button" id="telluserCancelbutton" class="btn btn-sm hidden">' + zzString(4) + '</button>')

        $('#telluserOKbutton').click(function(event) {
            console.log('okbutton')
            $('#telluser').modal('hide');
            if (theCallback) theCallback();
        });
        $('#telluserCancelbutton').click(function(event) {
            console.log('cancelbutton')
            $('#telluser').modal('hide');
        });
        $('#telluser').on('hidden.bs.modal', function(){
            $('#telluser').remove();
        })
    }
}

export function Message(msg,mode="error",callback= null){
    theCallback = callback;

    createdialog();
    
    if (mode == 'info'){
        $('#tellusertitle').html('<span class="mdi mdi-information-outline"></span> ' + zzString(1))
    } else if (mode == 'warning'){
        $('#tellusertitle').html('<span class="mdi mdi-alert-circle-outline"></span> ' + zzString(2))
    }else if (mode == 'error'){
        $('#tellusertitle').html('<span class="mdi mdi-alert-circle-outline"></span> ' + zzString(3))
    }

    $('#tellusermessage').html(msg);
    $('#telluser').modal('show');
}

export function Message2buttons(msg,callback){
    theCallback = callback;

    createdialog();

    $('#tellusertitle').html('<span class="mdi mdi-help-circle-outline"></span> ' + zzString(5))

    $('#telluserCancelbutton').removeClass('hidden')

    $('#tellusermessage').html(msg);
    $('#telluser').modal('show');

}