export function setCookie(customer, app,cname, cvalue, exdays=365) {
    if (customer == "") return;
    let name =  process.env.APPNAME + "." + customer + "." + app + "." + cname;

    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + cvalue + ";" + expires + ";path=/;SameSite=None; Secure";
}
export function getCookie(customer,app,cname,def="") {
    if (customer == "") return "";
    let name = process.env.APPNAME + "." + customer + "." + app + "." + cname + "=";

    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') 
            c = c.substring(1);
        if (c.indexOf(name) == 0) 
            return c.substring(name.length, c.length);
    }
    return def;
}
