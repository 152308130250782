import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./components/account/account";
import { Message } from "./components/Message/message";
import { notifyUser } from "./components/util/notification";

var theNumber;
const TESTNUMBER = 'R3bAyBmVktTQOdPjaxjWjK5tKOY2'
var theApp;
var theNotificationURL;
var theNotificationLink;

$('body').append('<div class="modal" id="NOTIFY" data-bs-keyboard="false"></div>');{
    $('#NOTIFY').append('<div class="modal-dialog modal-dialog-centered dialogs-dialog" id="NOTIFYtop"></div>');
    $('#NOTIFYtop').append('<div class="modal-content dialogs-content" id="NOTIFYcontent"></div>')

    $('#NOTIFYcontent').append('<div class="modal-header centered-header dialogs-header" id="NOTIFYheader"></div>')
    $('#NOTIFYcontent').append('<div id="NOTIFYbody" class="modal-body dialogs-body"></div>')
    $('#NOTIFYcontent').append('<div class="modal-footer dialogs-footer" id="NOTIFYfooter"></div>')

    $('#NOTIFYheader').append('<svg class="svg-icon27 lightsvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21"/></svg>')
    $('#NOTIFYheader').append('<div class="modal-title">Send NOTIFY</div>')
    $('#NOTIFYheader').append('<div id="NOTIFYclosebutton"><span class="mdi mdi-window-close"></span></div>');

    $('#NOTIFYbody').append('<label for="NOTIFY-title" class="dialogs-label">NOTIFY Title</label>');
    $('#NOTIFYbody').append('<input type="text" style="width:100%;" id="NOTIFY-title" placeholder="Input title here...">')
    $('#NOTIFY-title').val('Message from HR Bowling')

    $('#NOTIFYbody').append('<label for="NOTIFY-text" class="dialogs-label">NOTIFY Text</label>');
    $('#NOTIFYbody').append('<textarea style="width:100%;" rows="3" id="NOTIFY-text" placeholder="Input message here..."></textarea>')

    $('#NOTIFYbody').append('<div id="NOTIFY-progress"></div>')

    $('#NOTIFYfooter').append('<button type="button" id="NOTIFYTestbutton" class="btn dialogs-footerbutton">Test</button>')
    $('#NOTIFYfooter').append('<button type="button" id="NOTIFYOKbutton" class="btn dialogs-footerbutton dialogs-footerbutton-leftmost">Send</button>')


    $('#NOTIFYclosebutton').click(function(event) {
        $('#NOTIFY').modal('hide');
    });
    $('#NOTIFYOKbutton').on('click', function(event) {
        notifyUser(theNumber, $('#NOTIFY-title').val(), $('#NOTIFY-text').val(),theApp,theNotificationLink, theNotificationURL)
        .then(() => {
            $('#NOTIFY-progress').html('Notification send')
        })
        .catch(error => {
            $('#NOTIFY-progress').html('Notification Failed send<br>' + error)
        })
    });
    $('#NOTIFYTestbutton').on('click', function(event) {
        notifyUser(TESTNUMBER, $('#NOTIFY-title').val(), $('#NOTIFY-text').val(),theApp,theNotificationLink, theNotificationURL)
        .then(() => {
            $('#NOTIFY-progress').html('TEST notification send')
        })
        .catch(error => {
            $('#NOTIFY-progress').html('TEST notification Failed send<br>' + error)
        })
    });
}


export function newNOTIFY(number,appid,link, imgurl){
    theNumber = number;
    theApp = appid
    theNotificationURL = imgurl
    theNotificationLink = link
    $('#NOTIFY').modal('show')
}