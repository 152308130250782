console.log("international.js loaded")
import $ from "jquery";
require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light.css';
tippy.setDefaultProps({
    delay: 2000,
    arrow: true,
    animation: 'scale',
    touch: 'hold',
    allowHTML: true,
    theme: 'light',
    placement: 'auto-start',
});


import moment from 'moment';
import { getCookie, setCookie} from '../cookies/cookies';

import GBflag from './flags/GB.svg';
import USflag from './flags/US.svg';
import TWflag from './flags/TW.svg';
import DKflag from './flags/DK.svg';
import JPflag from './flags/JP.svg';
import SEflag from './flags/SE.svg';
import BEflag from './flags/BE.svg';
import CAflag from './flags/CA.svg';
import AUflag from './flags/AU.svg';
import ATflag from './flags/AT.svg';
import FRflag from './flags/FR.svg';
import ZAflag from './flags/ZA.svg';
import VNflag from './flags/VN.svg';
import NOflag from './flags/NO.svg';
import HKflag from './flags/HK.svg';
import PLflag from './flags/PL.svg';
import ITflag from './flags/IT.svg';
import DEflag from './flags/DE.svg';
import PHflag from './flags/PH.svg';
import FIflag from './flags/FI.svg';
import MTflag from './flags/MT.svg';
import NLflag from './flags/NL.svg';
import IEflag from './flags/IE.svg';
import MXflag from './flags/MX.svg';
import PTflag from './flags/PT.svg';
import MYflag from './flags/MY.svg';
import GRflag from './flags/GR.svg';
import CHflag from './flags/CH.svg';
import SIflag from './flags/SI.svg';
import NZflag from './flags/NZ.svg';
import CZflag from './flags/CZ.svg';
import HRflag from './flags/HR.svg';
import THflag from './flags/TH.svg';
import CYflag from './flags/CY.svg';
import TNflag from './flags/TN.svg';
import SGflag from './flags/SG.svg';
import LUflag from './flags/LU.svg';
import AEflag from './flags/AE.svg';
import PIRATEflag from './flags/pirate.svg';

import stringDB from './international.json';
import './international.css';


function zzString(id){
    const language = getLanguage() 
    if (id in stringDB){
        if ((language in stringDB[id]) && (stringDB[id][language] != "")) return stringDB[id][language];
        else if ('en' in stringDB[id]) return stringDB[id]['en'];
    }
    return "unknown string " + language + "/" + id;
}

var lang = getCookie(process.env.APPNAME,"international","language") || navigator.languages[0].substring(0,2).toLowerCase();
console.log('Language: ' + lang)
var clockformat = getCookie(process.env.APPNAME,"international","clock") || "HH:mm";
var dateformat = getCookie(process.env.APPNAME,"international","date") || "DD-MM-YYYY";
var firstdayofweek = getCookie(process.env.APPNAME,"international","firstdayofweek") || "1";
var currency = getCookie(process.env.APPNAME,"international","currency") || "0";

console.log('currency is : ' + currency)

const gbflag = new Image();
gbflag.className  = "smallflag";
gbflag.src = GBflag;
const usflag = new Image();
usflag.className  = "smallflag";
usflag.src = USflag;
const twflag = new Image();
twflag.className  = "smallflag";
twflag.src = TWflag;
const dkflag = new Image();
dkflag.className  = "smallflag";
dkflag.src = DKflag;
const jpflag = new Image();
jpflag.className  = "smallflag";
jpflag.src = JPflag;
const seflag = new Image();
seflag.className  = "smallflag";
seflag.src = SEflag;
const deflag = new Image();
deflag.className  = "smallflag";
deflag.src = DEflag;
const frflag = new Image();
frflag.className  = "smallflag";
frflag.src = FRflag;

function createDialog() {
    $('body').append('<div class="modal" id="internationalsettings"></div>');{
        $('#internationalsettings').append('<div class="modal-dialog modal-dialog-centered" id="internationalsettingstop"></div>');
        $('#internationalsettingstop').append('<div class="modal-content" id="internationalsettingscontent"></div>')

        $('#internationalsettingscontent').append('<div class="modal-header" id="internationalsettingsheader"></div>')
        $('#internationalsettingscontent').append('<div class="modal-body" id="internationalsettingsbody"></div>')
        $('#internationalsettingscontent').append('<div class="modal-footer" id="internationalsettingsfooter"></div>')

        $('#internationalsettingsheader').append('<div id="selectnewlanguage" class="modal-title">' + zzString(0) + '</div>')
        $('#internationalsettingsheader').append('<div id="internationalsettingsclosebutton"><i class="fa fa-times"></i></div>');

        $('#internationalsettingsbody').append('<div id="international-flag-subtitle" class="international-subtitle"><i class="fa fa-flag fa-lg international-icon"></i>' + zzString(1) + '</div>');{
        $('#internationalsettingsbody').append('<div id="language-select-dropdown" class="dropdown"></div>')
            $('#language-select-dropdown').append('<button id="dropdownMenu-language" class="btn dropdown-toggle btn-sm international-dropdownMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>');
                $('#dropdownMenu-language').append('<div id="currentLanguage" class="tiptool"></div>');
                    $('#currentLanguage').append('<div id="currentLanguageFlag"></div>');
                    $('#currentLanguage').append('<div id="currentLanguageName"></div>');

                    tippy('#currentLanguage', {
                        content: zzString(16),
                    });
                        
            
                $('#language-select-dropdown').append('<div id="language-select-dropdown-content" role="menu" class="dropdown-menu" aria-labelledby="dropdownMenu-language"></div>');
        
                $('#language-select-dropdown-content').append('\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="se" id="swedish"><div id="swedishflag"></div><div id="swedishtext">' + zzString(2) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="da" id="danish"><div id="danishflag"></div><div id="danishtext">' + zzString(3) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="en" id="english"><div id="englishflag"></div><div id="englishtext">' + zzString(4) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="us" id="american"><div id="americanflag"></div><div id="americantext">' + zzString(5) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="de" id="german"><div id="germanflag"></div><div id="germantext">' + zzString(17) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="fr" id="french"><div id="franceflag"></div><div id="frenchtext">' + zzString(30) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="tw" id="taiwanese"><div id="taiwanflag"></div><div id="taiwantext">' + zzString(6) + '</div></a></li>\
                    <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="languagemenuitem" data-action="jp" id="japanese"><div id="japaneseflag"></div><div id="japanesetext">' + zzString(7) + '</div></a></li>\
                    ');
                        $('#swedishflag').append(seflag);
                        $('#japaneseflag').append(jpflag);
                        $('#danishflag').append(dkflag);
                        $('#englishflag').append(gbflag);
                        $('#germanflag').append(deflag);
                        $('#franceflag').append(frflag);
                        $('#americanflag').append(usflag);
                        $('#taiwanflag').append(twflag);
        }
        $('#internationalsettingsbody').append('<div id="international-calendarday-subtitle" class="international-subtitle"><i class="fa fa-calendar-day fa-lg international-icon"></i>' + zzString(8) + '</div>');{
            $('#internationalsettingsbody').append('<div id="firstdayofweek-select-dropdown" class="dropdown"></div>')
                $('#firstdayofweek-select-dropdown').append('<button id="dropdownMenu-firstdayofweek" class="btn dropdown-toggle btn-sm international-dropdownMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>');
                    $('#dropdownMenu-firstdayofweek').append('<div id="currentfirstdayofweek"></div>');
                    $('#firstdayofweek-select-dropdown').append('<div id="firstdayofweek-select-dropdown-content" role="menu" class="dropdown-menu" aria-labelledby="dropdownMenu-language"></div>');
                    $('#firstdayofweek-select-dropdown-content').append('\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="firstdayofweekmenuitem" data-action="0" id="sunday">' + getDayName(0) + '</a></li>\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" role="firstdayofweekmenuitem" data-action="1" id="monday">' + getDayName(1) + '</a></li>\
                        ');
        }
        $('#internationalsettingsbody').append('<div id="international-calendar-subtitle" class="international-subtitle"><i class="fa fa-calendar fa-lg international-icon"></i><span>' + zzString(9) + '</span></div>');{
            $('#internationalsettingsbody').append('<div id="dateformat-select-dropdown" class="dropdown"></div>')
                $('#dateformat-select-dropdown').append('<button id="dropdownMenu-dateformat" class="btn dropdown-toggle btn-sm international-dropdownMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>');
                    $('#dropdownMenu-dateformat').append('<div id="currentdateformat"></div>');
                    $('#dateformat-select-dropdown').append('<div id="dateformat-select-dropdown-content" role="menu" class="dropdown-menu" aria-labelledby="dropdownMenu-language"></div>');
                    $('#dateformat-select-dropdown-content').append('\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="dmy-dateformat" role="dateformatmenuitem">DD-MM-YYYY</a></li>\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="mdy-dateformat" role="dateformatmenuitem">MM-DD-YYYY</a></li>\
                        ');
        }
        $('#internationalsettingsbody').append('<div id="international-clock-subtitle" class="international-subtitle"><i class="fa fa-clock fa-lg international-icon"></i>' + zzString(10) + '</div>');{
            $('#internationalsettingsbody').append('<div id="timeformat-select-dropdown" class="dropdown"></div>')
                $('#timeformat-select-dropdown').append('<button id="dropdownMenu-timeformat" class="btn dropdown-toggle btn-sm international-dropdownMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>');
                    $('#dropdownMenu-timeformat').append('<div id="currenttimeformat"></div>');
                    $('#timeformat-select-dropdown').append('<div id="timeformat-select-dropdown-content" role="menu" class="dropdown-menu" aria-labelledby="dropdownMenu-language"></div>');
                    $('#timeformat-select-dropdown-content').append('\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="h24-clock-format" role="timeformatmenuitem">HH:mm</a></li>\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="h12-clock-format" role="timeformatmenuitem">hh:mm a</a></li>\
                        ');
        }

        /*

        $('#internationalsettingsbody').append('<div id="international-currency-subtitle" class="international-subtitle"><i class="fa fa-coins fa-lg international-icon"></i>' + zzString(13) + '</div>');{
            $('#internationalsettingsbody').append('<div id="currency-select-dropdown" class="dropdown"></div>')
                $('#currency-select-dropdown').append('<button id="dropdownMenu-currency" class="btn dropdown-toggle btn-sm international-dropdownMenu" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>');
                    $('#dropdownMenu-currency').append('<div id="currentcurrency"></div>');
                    $('#currency-select-dropdown').append('<div id="currency-select-dropdown-content" role="menu" class="dropdown-menu" aria-labelledby="dropdownMenu-language"></div>');
                    $('#currency-select-dropdown-content').append('\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="usd-currency" data-action="0" role="currencymenuitem"><i class="fa-solid fa-dollar-sign"></i></a></li>\
                        <li role="presentation"><a class="dropdown-item international-dropdown_item" href="#" id="eur_currency"  data-action="1" role="currencymenuitem"><i class="fa-solid fa-euro-sign"></i></a></li>\
                        ');
        }

        */




        $('#internationalsettingsfooter').append('<button type="button" id="internationalsettingsCancelbutton" class="btn btn-sm">' + zzString(11) + '</button>')
        $('#internationalsettingsfooter').append('<button type="button" id="internationalsettingsOKbutton" class="btn btn-sm">' + zzString(12) + '</button>')

        $('#internationalsettingsclosebutton').click(function(event) {
            $('#internationalsettings').modal('hide');
        });
        $('#internationalsettingsCancelbutton').on('click', function(event) {
            $('#internationalsettings').modal('hide');
        });
        $('#internationalsettingsclosebutton').on("keyup", function (ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                ev.stopPropagation();
                $('#internationalsettings').modal('hide');
            }
        });
        $('.dropdown-menu a[role="languagemenuitem"]').on('click', function(ev){
            let target = $(ev.target).closest('a[role="languagemenuitem"]')[0];
            let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
            setLanguage(action);
        });
        $('.dropdown-menu a[role="firstdayofweekmenuitem"]').on('click', function(ev){
            let target = $(ev.target).closest('a[role="firstdayofweekmenuitem"]')[0];
            let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
            setFirstDayOfWeek(parseInt(action));
        });
        $('.dropdown-menu a[role="currencymenuitem"]').on('click', function(ev){
            let target = $(ev.target).closest('a[role="currencymenuitem"]')[0];
            let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
            setCurrency(parseInt(action));
        });
        $('.dropdown-menu a[role="dateformatmenuitem"]').on('click', function(ev){
            $('#currentdateformat').html($(this).html())
        });
        $('.dropdown-menu a[role="timeformatmenuitem"]').on('click', function(ev){
            $('#currenttimeformat').html($(this).html())
        });
        function setLanguage(action){
            $('#currentLanguage').prop('value',action)
            $('#currentLanguageFlag').empty();
            switch (action) {
                case 'jp':
                    $('#currentLanguageFlag').append(jpflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(7));
                    break;
                case 'da':
                    $('#currentLanguageFlag').append(dkflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(3));
                    break;
                    case 'us':
                    $('#currentLanguageFlag').append(usflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(5));
                    break;
                case 'tw':
                    $('#currentLanguageFlag').append(twflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(6));
                    break;
                case 'se':
                    $('#currentLanguageFlag').append(seflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(2));
                    break;
                case 'de':
                    $('#currentLanguageFlag').append(deflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(17));
                    break;
                case 'fr':
                    $('#currentLanguageFlag').append(frflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(30));
                    break;
                default:
                case 'en':
                    $('#currentLanguageFlag').append(gbflag.cloneNode(true));
                    $('#currentLanguageName').html(zzString(4));
                    break;
            }
        }
        function setFirstDayOfWeek(action){
            $('#currentfirstdayofweek').prop('value',action)
            $('#currentfirstdayofweek').html(getDayName(action));
        }
        function setCurrency(action){
            $('#currentcurrency').prop('value',action)
            $('#currentcurrency').html(getCurrencyName(action));
        }

        $('#internationalsettingsOKbutton').on('click', function(event) {
//            $('#internationalsettings').modal('hide');
            lang = $('#currentLanguage').prop('value');
            console.log('Language changed to : ' + lang)
            setCookie(process.env.APPNAME, "international","language",lang)
            firstdayofweek = $('#currentfirstdayofweek').prop('value');
            setCookie(process.env.APPNAME, "international","firstdayofweek",firstdayofweek)
            currency = $('#currentcurrency').prop('value');
            console.log('Currency changed to : ' + currency)
            setCookie(process.env.APPNAME,"international","currency",currency)
            dateformat = $('#currentdateformat').html();
            setCookie(process.env.APPNAME, "international","date",dateformat)
            clockformat = $('#currenttimeformat').html();
            setCookie(process.env.APPNAME, "international","clock",clockformat)
            console.log('reloading')
            location.reload();
        });
        $('#internationalsettings').on('shown.bs.modal', function(){
            setLanguage(getLanguage());
            setFirstDayOfWeek(getWeekstart())
            setCurrency(getCurrency())
            $('#currentdateformat').html(dateformat)
            $('#currenttimeformat').html(clockformat)
            $('#dropdownMenu-language').focus();
        })
        $('#internationalsettings').on("keyup", function (ev) {
            if ((ev.keyCode === 13) && !$('#internationalsettingsclosebutton').is(":focus")) {
                ev.preventDefault();
                ev.stopPropagation();
                $('#internationalsettingsOKbutton').trigger('click');
            }
        });

        $('#internationalsettings').on('hidden.bs.modal', function(){
            $('#internationalsettings').remove();
        })
    }
}
export function changeInternationalSettings(){
    createDialog();
    $('#internationalsettings').modal("show");
}
export function getLanguage(){
    return lang;
}
export function getWeekstart(){
    return parseInt(firstdayofweek)
}
export function getCurrency(){
    return parseInt(currency);
}
export function getDayName(day){
    return zzString(40+day)
}
export function getShortDayName(day){
    return zzString(142+day)
}
export function getMonthName(day){
    return zzString(108+day)
}
export function getShortMonthName(day){
    return zzString(18+day)
}

function getCurrencyName(id){
    if (id == 1) return zzString(14);
    return zzString(15);
}
export function is24hformat(){
    return (clockformat == "HH:mm")
}

export function getTimeString(date){
    if ((typeof date) == 'string'){
        const el = date.split(" ");
        if (el.length == 1) date = "2022-01-01 " + date;
    }
    return moment(date).format(clockformat);
}
export function getDateString(date){
    return moment(date).format(dateformat)
}
export function getShortDateString(date){
    return moment(date).format(dateformat.substring(0,5))
}

export function getVeryShortDateString(date){
    return moment(date).format('MMM YYYY')
}

export function getFlag(country){
    if (country == undefined) return '<img height="16px" src="' + PIRATEflag + '">'

    if (country == "Taiwan") return '<img height="16px" src="' + TWflag + '">'
    if (country == "Denmark") return '<img height="16px" src="' + DKflag + '">'
    if (country == "Sweden") return '<img height="16px" src="' + SEflag + '">'
    if (country == "United Kingdom") return '<img height="16px" src="' + GBflag + '">'
    if (country == "United States") return '<img height="16px" src="' + USflag + '">'
    if (country == "Japan") return '<img height="16px" src="' + JPflag + '">'
    if (country == "Belgium") return '<img height="16px" src="' + BEflag + '">'
    if (country == "Canada") return '<img height="16px" src="' + CAflag + '">'
    if (country == "Australia") return '<img height="16px" src="' + AUflag + '">'
    if (country == "Austria") return '<img height="16px" src="' + ATflag + '">'
    if (country == "France") return '<img height="16px" src="' + FRflag + '">'
    if (country == "South Africa") return '<img height="16px" src="' + ZAflag + '">'
    if (country == "Vietnam") return '<img height="16px" src="' + VNflag + '">'
    if (country == "Norway") return '<img height="16px" src="' + NOflag + '">'
    if (country == "Hong Kong") return '<img height="16px" src="' + HKflag + '">'
    if (country == "Poland") return '<img height="16px" src="' + PLflag + '">'
    if (country == "Italy") return '<img height="16px" src="' + ITflag + '">'
    if (country == "Germany") return '<img height="16px" src="' + DEflag + '">'
    if (country == "Philippines") return '<img height="16px" src="' + PHflag + '">'
    if (country == "Finland") return '<img height="16px" src="' + FIflag + '">'
    if (country == "Malta") return '<img height="16px" src="' + MTflag + '">'
    if (country == "Netherlands") return '<img height="16px" src="' + NLflag + '">'
    if (country == "Ireland") return '<img height="16px" src="' + IEflag + '">'
    if (country == "Mexico") return '<img height="16px" src="' + MXflag + '">'
    if (country == "Portugal") return '<img height="16px" src="' + PTflag + '">'
    if (country == "Malaysia") return '<img height="16px" src="' + MYflag + '">'
    if (country == "Greece") return '<img height="16px" src="' + GRflag + '">'
    if (country == "Switzerland") return '<img height="16px" src="' + CHflag + '">'
    if (country == "Slovenia") return '<img height="16px" src="' + SIflag + '">'
    if (country == "New Zealand") return '<img height="16px" src="' + NZflag + '">'
    if (country == "Czechia") return '<img height="16px" src="' + CZflag + '">'
    if (country == "Croatia") return '<img height="16px" src="' + HRflag + '">'
    if (country == "Thailand") return '<img height="16px" src="' + THflag + '">'
    if (country == "Cyprus") return '<img height="16px" src="' + CYflag + '">'
    if (country == "Tunisia") return '<img height="16px" src="' + TNflag + '">'
    if (country == "Singapore") return '<img height="16px" src="' + SGflag + '">'
    if (country == "Luxembourg") return '<img height="16px" src="' + LUflag + '">'
    if (country == "United Arab Emirates") return '<img height="16px" src="' + AEflag + '">'

    return '<span>' + country + '</span>';
}

